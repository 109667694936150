import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useEffect, useReducer, useState } from "react";
import { faClock, faClockRotateLeft, faDatabase, faEdit, faEye, faFolder, faHouse, faMessage, faPlus, faSave, faTrash, faUpload, faUserCheck, faUserPen, faCalendar, faFlagCheckered, faHourglassEnd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import SelectSearch from "../../../../components/ui/SelectSearch";
import { useListByUserMutation } from "../../../../api/tracker/project";
import { useProjectSetMutation, useProjectSetPrepareParameterMutation, useProjectSetPrepareUserMutation, useTimetableListMutation, useUserListMutation, useUpdateMutation, useListQuery, useParameterSetMassMutation, useFileListMutation, useTrackerListCompletedByTaskMutation } from "../../../../api/tracker/taskR";
import ModalTaskChangeProject from "./ModalTaskChangeProject";
import StringToDateTime from "../../../../util/StringToDateTime";
import ModalTaskUser from "./ModalTaskUser";
import StringToInputDate from "../../../../util/StringToInputDate";
import ModalTaskRlTimetable from "./ModalTaskRlTimetable";
import ModalTaskRlTimetableRemove from "./ModalTaskRlTimetableRemove";
import FormToJson from "../../../../util/FormToJson";
import { ValidateForm } from "../../../../util/ValidateForm";
import Parameters from "../../../General/Parameters";
import { useParameterListMutation } from "../../../../api/tracker/taskR";
import FileList from "../../../General/FileList";
import FileUploadModal from "../../../General/FileUploadModal";
import FilterDate from "../../../General/FilterDate";
import { differenceInDays } from 'date-fns';
import StringToDate from '../../../../util/StringToDate';


function ModalTaskR(props) {
    const { show, onHide, edit, model } = props;

    function DateStartEnd({ date_start, date_end, date_completed }) {
        if (date_start !== null & date_end !== null) {

            const diff_days = differenceInDays(
                new Date(new Date()),
                new Date(date_completed)
            )
            return (<>
                <FontAwesomeIcon icon={faFlagCheckered} className='mr-10' /> {StringToDate(date_completed)}г  {diff_days > 0 && <small> (д.б. {StringToDate(date_end)}г)</small>}
                {diff_days > 0 && <div className='text-danger'><b><FontAwesomeIcon icon={faHourglassEnd} className='mr-10' /> Просрочено {diff_days} дн.</b></div>}
            </>)
        }
        else if (date_start !== null & date_end === null) {
            return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' />С {StringToDate(date_start)}</>)
        }
        else {
            return null;
        }
    } 

    let currentDate = new Date();
    const [validErrors, setValidErrors] = useState([]);
    //const [reqErrors, setReqErrors] = useState('');

    //МОДАЛЬНЫЕ ОКНА
    const [modalUserShow, setModalUserShow] = useState(false);
    function modalUserHandler(e) {
        e.preventDefault();
        setModalUserShow(true)
    }
    const [modalFilesAddShow, setModalFilesAddShow] = useState(false);

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setLoadingOpen(true);
            setLoadingSave(false);
            setLog(null);
            setInputProjectLog(null);
            setValidErrors([]);

            //Загружаем проекты
            listProject();
            //Загружаем пользователей
            listUser({ ID_TaskR: model.ID });
            //Загрузка расписаний
            listTimetable({ ID_TaskR: model.ID });
            //Загружаем параметры
            listParameter({ ID_TaskR: model.ID });
            //Загружаем файлы
            listFile({ ID_TaskR: model.ID });
        }
        //Действия при закрытии модального окна
        if (show == false) {

        }
    }, [show]);


    const [loadingOpen, setLoadingOpen] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const [log, setLog] = useState(null);

    //TAB
    const [tab1Show, setTab1Show] = useState(true);
    const [tab2Show, setTab2Show] = useState(false);
    const [tab2Count, setTab2Count] = useState(0);
    const [tab3Show, setTab3Show] = useState(false);
    const [tab4Show, setTab4Show] = useState(false);

    function tabHandleClick(e) {
        if (e.currentTarget.id === "tab_1") {
            setTab1Show(true);
            setTab2Show(false);
            setTab3Show(false);
            setTab4Show(false);
        }
        if (e.currentTarget.id === "tab_2") {
            setTab1Show(false);
            setTab2Show(true);
            setTab3Show(false);
            setTab4Show(false);
        }
        if (e.currentTarget.id === "tab_3") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(true);
            setTab4Show(false);
        }
        if (e.currentTarget.id === "tab_4") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(false);
            setTab4Show(true);
        }
    }


    //ОПИСАНИЕ
    const [inputDescription, setInputDescription] = useState('');
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    //Событие изменения описания
    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setInputDescription(html);
    }, [editorState]);

    /***********************************START ПРОЕКТЫ****************************************/

    const [listProject, { data: listProjectData, status: listProjectStatus, isLoading: listProjectIsLoading }] = useListByUserMutation();
    const [projectSetPrepareUser, { data: projectSetPrepareUserData, status: projectSetPrepareUserStatus, isLoading: projectSetPrepareUserIsLoading }] = useProjectSetPrepareUserMutation();
    const [projectSetPrepareParameter, { data: projectSetPrepareParameterData, status: projectSetPrepareParameterStatus, isLoading: projectSetPrepareParameterIsLoading }] = useProjectSetPrepareParameterMutation();
    const [projectSet, { data: projectSetData, status: projectSetStatus, isLoading: projectSetIsLoading }] = useProjectSetMutation();

    const [modalChangeProjectShow, setModalChangeProjectShow] = useState(false);

    const [idProjectNew, setIdProjectNew] = useState([]);
    const [inputProject, setInputProject] = useState([]);
    const [inputProjectSelect, setInputProjectSelect] = useState(null);
    const [inputProjectLog, setInputProjectLog] = useState(null);
    const [loadingProject, setLoadingProject] = useState(false);
    const [modelProjectSetPrepareUser, setModelProjectSetPrepareUser] = useState([]);
    const [modelProjectSetPrepareParameter, setModelProjectSetPrepareParameter] = useState([]);

    //Подгрузка проектов
    useEffect(() => {
        if (listProjectStatus === 'fulfilled') {

            const jsonForm = [];

            for (let i = 0; i < listProjectData.Data.length; i++) {
                jsonForm.push(
                    {
                        value: listProjectData.Data[i].ID,
                        label: listProjectData.Data[i].Name_Full,
                        use_rtasks: listProjectData.Data[i].Use_Rtasks
                    })
            }
            setInputProject(jsonForm.filter(a => a.use_rtasks === true));
            setInputProjectSelect(jsonForm.find(a => a.value == model.ID_Project));
        }
    }, [listProjectStatus]);

    //Изменение проекта
    function changeProject(e) {
        setInputProjectLog(null);
        setLoadingProject(true);
        setIdProjectNew(e.value);
        projectSetPrepareUser({ ID_TaskR: model.ID, ID_Project_New: e.value });
        setInputProjectSelect(e)
    }

    //Шаг 1. Проверка пользователей на удаление
    useEffect(() => {
        if (projectSetPrepareUserStatus === 'fulfilled') {
            if (projectSetPrepareUserData.Status == 1) {
                //Переходим к шагу №2
                projectSetPrepareParameter({ ID_TaskR: model.ID, ID_Project_New: idProjectNew });
            }
            else {
                setInputProjectLog(projectSetPrepareUserData.Error);
                setLoadingProject(false);
            }

        }
        if (projectSetPrepareUserStatus === 'rejected') {
            setInputProjectLog('Неизвестная ошибка!');
            setLoadingProject(false);
        }
    }, [projectSetPrepareUserStatus]);

    //Шаг 2. Проверка параметров на удаление
    useEffect(() => {
        if (projectSetPrepareParameterStatus === 'fulfilled') {
            if (projectSetPrepareParameterData.Status == 1) {
                //Если есть пользователи/параметры, которые будут удалены, то показываем модальное окно с предупреждением
                if (projectSetPrepareParameterData.Data.length > 0 || projectSetPrepareUserData.Data.length > 0) {
                    setModalChangeProjectShow(true);
                    setModelProjectSetPrepareUser(projectSetPrepareUserData.Data)
                    setModelProjectSetPrepareParameter(projectSetPrepareParameterData.Data)
                    setLoadingProject(false)
                }
                else {
                    projectSet({ ID_TaskR: model.ID, ID_Project: inputProjectSelect.value });
                }
            }
            else {
                setInputProjectLog(projectSetPrepareParameterData.Error);
                setLoadingProject(false);
            }
            //setLoadingProject(false);
        }
        if (projectSetPrepareParameterStatus === 'rejected') {

            setInputProjectLog('Неизвестная ошибка!');
            setLoadingProject(false);
        }
    }, [projectSetPrepareParameterStatus]);

    //Подтверждение изменения проекта
    useEffect(() => {
        if (projectSetStatus === 'fulfilled') {
            if (projectSetData.Status == 1) {

            }
            else {
                setInputProjectLog(projectSetData.Error);
            }
        }
        if (projectSetStatus === 'rejected') {
            setInputProjectLog('Неизвестная ошибка!');
        }
        setLoadingProject(false);
    }, [projectSetStatus]);
    /***********************************END ПРОЕКТЫ*****************************************/
    /*********************************START ПОЛЬЗОВАТЕЛИ*************************************/
    const [listUser, { data: listUserData, status: listUserStatus, isLoading: listUserIsLoading }] = useUserListMutation();

    /**********************************END ПОЛЬЗОВАТЕЛИ**************************************/
    /*************************************START РАСПИСАНИЕ**************************************/
    const [selectedModelTimetable, setSelectedModelTimetable] = useState(null);

    const [modalTimetableShow, setModalTimetableShow] = useState(false);
    const [modalTimetableRemoveShow, setModalTimetableRemoveShow] = useState(false);

    function modalTimetableAddHandler(e) {
        e.preventDefault();
        setSelectedModelTimetable(null);
        setModalTimetableShow(true);
    }
    function timetableEditHandler(e, model) {
        e.preventDefault();
        setSelectedModelTimetable(model);
        setModalTimetableShow(true);
    }
    function timetableRemoveHandler(e, model) {
        e.preventDefault();
        setSelectedModelTimetable(model);
        setModalTimetableRemoveShow(true);
    }

    const [listTimetable, { data: listTimetableData, status: listTimetableStatus, isLoading: listTimetableIsLoading }] = useTimetableListMutation();

    /*************************************END РАСПИСАНИЕ***************************************/
    /****************************START ДОБАВЛЕНИЕ/ИЗМЕНЕНИЕ ЗАДАЧИ******************************/
    function updateHandler(e) {

        e.preventDefault();
        setLoadingSave(true);
        var jsonForm = FormToJson(e.target);

        var validForm = ValidateForm(e.target, setValidErrors);

        if (validForm) {
            if (inputProjectSelect === null || inputProjectSelect === undefined) {
                setInputProjectLog('Выберите проект');
                setLoadingSave(false);
            }
            else {
                jsonForm['ID'] = model.ID;
                jsonForm['ID_Priority'] = model.ID_Priority;
                jsonForm['Description'] = null;
                let description = convertToHTML(editorState.getCurrentContent())

                if (description !== '<p></p>') {
                    jsonForm['Description'] = description;
                }

                update(jsonForm);
            }
        }
        else {
            setLoadingSave(false);
        }
    }

    //1 ЗАПРОС. ОБРАБОТКА ОТПРАВЛЕННОЙ ФОРМЫ
    const [update, { data: updateData, status: updateStatus, isLoading: updateIsLoading }] = useUpdateMutation();
    useEffect(() => {
        if (updateStatus === 'fulfilled') {
            if (updateData.Status == 1) {
                setLoadingSave(false);
                listActive.refetch();
                listArchive.refetch();
                listFuture.refetch();
                parameterSetMass(parameterState.data);//Сохранение параметров               
            }
            else {
                setLog(updateData.Error);
                setLoadingSave(false);
            }
        }
        if (updateData === 'rejected') {
            setLog('Неизвестная ошибка!');
            setLoadingSave(false);
        }
    }, [updateStatus]);

    //2 ЗАПРОС. СОХРАНЕНИЕ ПАРАМЕТРОВ
    const [parameterSetMass, { data: parameterSetMassData, status: parameterSetMassStatus }] = useParameterSetMassMutation();
    useEffect(() => {
        if (parameterSetMassStatus === 'fulfilled') {
            if (parameterSetMassData.Status == 1) {
                onHide();
            }
            else {
                setLog(parameterSetMassData.Error)
                setLoadingSave(false);
            }
        }
        if (parameterSetMassStatus === 'rejected') {
            setLog('Неизвестная ошибка!');
            setLoadingSave(false);
        }
    }, [parameterSetMassStatus]);
    /*****************************END ДОБАВЛЕНИЕ/ИЗМЕНЕНИЕ ЗАДАЧИ*******************************/

    const listActive = useListQuery({ ID_Status: 1 });
    const listArchive = useListQuery({ ID_Status: 0 });
    const listFuture = useListQuery({ ID_Status: 2 });

    /*------------------------------START ПОДГРУЗКА ПАРАМЕТРОВ--------------------------*/
    const [listParameter, { data: listParameterData, status: listParameterStatus, isLoading: listParameterIsLoading }] = useParameterListMutation();

    const [parameterState, parameterDispatch] = useReducer(referenceReducer, { data: [] });
    function referenceReducer(state, action) {

        switch (action.type) {
            case 'load': {
                //Добавляем необходимые поля
                const newState = action.data.map(obj => {
                    return { ...obj, Error: false, ID_TaskR: model.ID };
                })
                return { data: newState }
            }
            case 'set': {
                /*
                ID	Name
                1	Логический
                2	Число
                3	Строка
                4	Справочник
                5	Дата
                */
                var val_bool = null;
                var val_float = null;
                var val_string = null;
                var val_date = null;

                if (action.id_type === 1) {
                    val_bool = action.value_bool;
                }
                if (action.id_type === 2) {
                    val_float = action.value_float;
                }
                if (action.id_type === 3) {
                    val_string = action.value_string;
                }
                if (action.id_type === 4) {
                    val_float = action.value_float;
                }
                if (action.id_type === 5) {
                    val_date = action.value_date;
                }

                if (val_string === '') {
                    val_string = null;
                }

                const newState = state.data.map(obj => {
                    if (obj.ID_Parameter === action.id_parameter) {
                        return { ...obj, Value_Bool: val_bool, Value_Float: val_float, Value_String: val_string, Value_Date: val_date };
                    }
                    return obj;
                })
                return { data: newState }

            }
        }
    }

    useEffect(() => {
        if (listParameterStatus === 'fulfilled') {
            //setLoadingParameters(false);

            if (listParameterData.Data != null) {
                parameterDispatch({ type: 'load', data: listParameterData.Data })
            }
            else {

            }
        }
    }, [listParameterStatus]);

    /*-------------------------------END ПОДГРУЗКА ПАРАМЕТРОВ---------------------------*/
    /*-------------------------------------START ФАЙЛЫ----------------------------------*/
    const [listFile, { data: listFileData, status: listFileStatus, isLoading: listFileIsLoading }] = useFileListMutation();

    const [listFileState, listFileDispatch] = useReducer(listFileReducer, { data: [] });
    function listFileReducer(state, action) {
        switch (action.type) {
            case 'load': {
                //Добавляем необходи
                setTab2Count(action.data.length);
                const newState = action.data.map(obj => {
                    return { ...obj, download_loading: false, download_error: false };
                })
                return { data: newState }
            }
            case 'download_loading': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return { ...obj, download_loading: action.loading };
                    }
                    else {
                        return { ...obj }
                    }
                })

                return { data: newState }
            }
            case 'download_error': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return { ...obj, download_error: action.val };
                    }
                    else {
                        return { ...obj }
                    }
                })

                return { data: newState }
            }
            case 'remove': {
                setTab2Count(tab2Count - 1)
                return { data: state.data.filter(a => a.ID !== action.id) }
            }
            case 'edit': {
                const newState = state.data.map(obj => {
                    if (obj.ID === action.id) {
                        return {
                            ...obj
                            , Name: action.name
                            , Name_Full: action.name + obj.Extension
                        };
                    }
                    else {
                        return { ...obj }
                    }
                })

                return { data: newState }
            }
            case 'clear': {
                setTab2Count(0)
                return { data: [] }
            }
        }
    }
    useEffect(() => {
        if (listFileStatus === 'fulfilled') {
            listFileDispatch({ type: 'load', data: listFileData.Data });

        }
    }, [listFileStatus]);
    /*--------------------------------------END ФАЙЛЫ-----------------------------------*/

    /*--------------------------------------START СПИСОК ВЫПОЛНЕННЫХ ЗАДАЧ-----------------------------------*/
    const [trackerListCompletedByTask, { status: ListCompletedStatus }] = useTrackerListCompletedByTaskMutation();
    const [listCompleted, setListCompleted] = useState([]);

    function changDateHandler(date_start, date_end) {

        trackerListCompletedByTask({ ID_TaskR: model.ID, Date_Start: date_start, Date_End: date_end })
            .unwrap()
            .then(res => {
                if (res.Status === 1) {
                    setListCompleted(res.Data);
                }
            });
    }
    /*---------------------------------------END СПИСОК ВЫПОЛНЕННЫХ ЗАДАЧ------------------------------------*/

    return (
        <>
            {model !== undefined && (
                <Modal show={show} onHide={onHide} dialogClassName="modal-80">
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {edit ?
                                (<><i className="mr-5"><FontAwesomeIcon icon={faEdit} /></i> #{model.ID} Изменение регулярной задачи</>)
                                :
                                (<><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i> Добавление регулярной задач</>)
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="my-form" onSubmit={(e) => updateHandler(e)}>


                            <div className="row" >
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="main-card card">
                                                <div className="card-header-tab card-header-tab-animation card-header">
                                                    <div className="card-header-title">
                                                        {model.Name_Status}
                                                    </div>
                                                    <ul className="nav">
                                                        <li className="nav-item">
                                                            <a
                                                                id="tab_1"
                                                                onClick={tabHandleClick}
                                                                data-toggle="tab"
                                                                className={tab1Show ? "nav-link active show" : "nav-link show"}
                                                            >
                                                                <FontAwesomeIcon icon={faHouse} /> Основное
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                id="tab_3"
                                                                onClick={tabHandleClick}
                                                                data-toggle="tab"
                                                                className={tab3Show ? "nav-link active show" : "nav-link show"}
                                                            >
                                                                <FontAwesomeIcon icon={faDatabase} /> Параметры
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                id="tab_2"
                                                                onClick={tabHandleClick}
                                                                data-toggle="tab"
                                                                className={tab2Show ? "nav-link active show" : "nav-link show"}
                                                            >
                                                                <FontAwesomeIcon icon={faFolder} /> Файлы {tab2Count !== 0 && ('(' + tab2Count + ')')}
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                id="tab_4"
                                                                onClick={tabHandleClick}
                                                                data-toggle="tab"
                                                                className={tab4Show ? "nav-link active show" : "nav-link show"}
                                                            >
                                                                <FontAwesomeIcon icon={faClockRotateLeft} /> История выполнений
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="card-body">
                                                    <div className="tab-content">
                                                        <div className={tab1Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <div className="card-block-title">Основное</div>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                        <Form.Label>Название задачи</Form.Label>
                                                                        <Form.Control name="Name" type="text" disabled={loadingSave} defaultValue={model.Name} valid='[{"id": "empty"}]' />
                                                                        {validErrors.find((el) => el.name === "Name") !== undefined && (
                                                                            <div className="form_error">
                                                                                {validErrors.find((el) => el.name === "Name").error}
                                                                            </div>
                                                                        )}
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                                                        <Form.Label>Проект</Form.Label>
                                                                        <SelectSearch name="ID_Project" placeholder='Выберите проект' className="z-index-max" disabled={loadingProject} options={inputProject} defaultValue={inputProjectSelect} onChange={(e) => changeProject(e)} valid='[{"id": "empty"}]' />
                                                                        {inputProjectLog !== null && <div className="form_error">{inputProjectLog}</div>}
                                                                        {loadingProject === true && (
                                                                            <div className="loader_01"></div>
                                                                        )}
                                                                        <ModalTaskChangeProject
                                                                            show={modalChangeProjectShow}
                                                                            onHide={() => setModalChangeProjectShow(false)}
                                                                            id_task={model.ID}
                                                                            id_project={inputProjectSelect}
                                                                            modelProjectSetPrepareUser={modelProjectSetPrepareUser}
                                                                            modelProjectSetPrepareParameter={modelProjectSetPrepareParameter}
                                                                            projectSet={projectSet}
                                                                            projectSetData={projectSetData}
                                                                            projectSetStatus={projectSetStatus}
                                                                        />
                                                                        {validErrors.find((el) => el.name === "ID_Project") !== undefined && (
                                                                            <div className="form_error">
                                                                                {validErrors.find((el) => el.name === "ID_Project").error}
                                                                            </div>
                                                                        )}
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                        <Form.Label>Описание</Form.Label>
                                                                        <Editor
                                                                            editorState={editorState}
                                                                            onEditorStateChange={setEditorState}
                                                                            disabled={loadingSave}
                                                                            editorClassName={loadingSave && 'editor-class-disabled'}
                                                                        />
                                                                    </Form.Group>
                                                                    {listParameterStatus !== 'fulfilled' ? (
                                                                        <div>
                                                                            <div className="loader_01"></div>
                                                                        </div>
                                                                    ) :
                                                                        <Parameters list={parameterState.data.filter(a => a.ID_Display === 8)} dispatch={parameterDispatch} />
                                                                    }
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="card-block-title">Даты</div>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <Form.Group className="mb-3" key={model.ID}>
                                                                                <Form.Label>Дата начала</Form.Label>
                                                                                <Form.Control name="Date_Start" type="date" defaultValue={model.Temp === true ? StringToInputDate(currentDate) : StringToInputDate(model.Date_Start)} valid='[{"id": "empty"}]' />
                                                                            </Form.Group>
                                                                            {validErrors.find((el) => el.name === "Date_Start") !== undefined && (
                                                                                <div className="form_error">
                                                                                    {validErrors.find((el) => el.name === "Date_Start").error}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <Form.Group className="mb-3" key={model.ID}>
                                                                                <Form.Label>Дата завершения</Form.Label>
                                                                                <Form.Control name="Date_End" type="date" defaultValue={StringToInputDate(model.Date_End)} />
                                                                            </Form.Group>


                                                                        </div>
                                                                    </div>
                                                                    {listParameterStatus !== 'fulfilled' ? (
                                                                        <div>
                                                                            <div className="loader_01"></div>
                                                                        </div>
                                                                    ) :
                                                                        <Parameters list={parameterState.data.filter(a => a.ID_Display === 9)} dispatch={parameterDispatch} />
                                                                    }
                                                                    <div className="card-block-title">Пользователи</div>
                                                                    <div className="text-end mt-15">
                                                                        <div>
                                                                            <button onClick={(e) => modalUserHandler(e)} className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary"><FontAwesomeIcon icon={faUserPen} /> Изменить...</button>
                                                                            <ModalTaskUser show={modalUserShow} onHide={() => setModalUserShow(false)} id_task={model.ID} listUser={listUser} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="div">
                                                                        <div className="scrollbar-container ps ps--active-y">
                                                                            <div className="p-2">
                                                                                {listUserIsLoading === true || listUserData === undefined ? (
                                                                                    <div className="loader_01"></div>
                                                                                ) : (
                                                                                    listUserData.Data.map((model) => (
                                                                                        <div key={model.ID_User} className="row row_select task_user_row">
                                                                                            <div className="col-md-12">
                                                                                                {model.ID_Action === 1 && (
                                                                                                    <FontAwesomeIcon fontSize={'20px'} icon={faEye} className="mr-10 float-start text-warning" />
                                                                                                )}
                                                                                                {model.ID_Action === 2 && (
                                                                                                    <FontAwesomeIcon fontSize={'20px'} icon={faUserCheck} className="mr-10 float-start text-success" />
                                                                                                )}
                                                                                                {model.Complete === true ? (<del>{model.Name_User}</del>) : (<div>{model.Name_User}</div>)}
                                                                                                {model.Complete === true && (
                                                                                                    <>
                                                                                                        <div className="task_user_comment">{model.Complete_Comment}</div>
                                                                                                        <div className="task_user_date">{StringToDateTime(model.Complete_Date)}</div>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {listParameterStatus !== 'fulfilled' ? (
                                                                        <div>
                                                                            <div className="loader_01"></div>
                                                                        </div>
                                                                    ) :
                                                                        <Parameters list={parameterState.data.filter(a => a.ID_Display === 10)} dispatch={parameterDispatch} />
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/*reqStatus == 'rejected' && <div className="form_error">Неизвестная ошибка! Обратитесь в техническую поддержку</div>*/}
                                                        </div>
                                                        <div className={tab2Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                                            <div className="">
                                                                <button
                                                                    className="btn-square btn-hover-shine btn btn-secondary mt-10"
                                                                    type="button"
                                                                    onClick={() => setModalFilesAddShow(true)}
                                                                >
                                                                    <FontAwesomeIcon icon={faUpload} className="mr-5" /> Добавить файлы
                                                                </button>
                                                                <FileUploadModal show={modalFilesAddShow} onHide={() => setModalFilesAddShow(false)} type={2} data={model.ID} listApi={listFile} />
                                                            </div>
                                                            <div className="mt-20">
                                                                <FileList Type={2} ID_Object={model.ID} listDispatch={listFileDispatch} listState={listFileState} />
                                                            </div>
                                                        </div>
                                                        <div className={tab3Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                                            <Parameters list={parameterState.data.filter(a => a.ID_Display === 12)} dispatch={parameterDispatch} />
                                                        </div>
                                                        <div className={tab4Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                                            <div>
                                                                <FilterDate defaultVal={4} changHandler={changDateHandler} />

                                                                <div className="mt-15">
                                                                    {ListCompletedStatus !== 'fulfilled' ?
                                                                        (
                                                                            <div className="loader_01"></div>
                                                                        )
                                                                        :
                                                                        (
                                                                            <div className="table-responsive">
                                                                                <table className="table">
                                                                                    <tbody>
                                                                                        {listCompleted.map((model) => (
                                                                                            <tr className='row_select' key={model.ID}>
                                                                                                <td>
                                                                                                    {model.User_Name} <br></br>
                                                                                                    {StringToDateTime(model.Date_Add)}

                                                                                                </td>

                                                                                                <td><DateStartEnd date_start={model.Date} date_end={model.Date_End} date_completed={model.Completed_Date} /></td>
                                                                                                <td>
                                                                                                    <td>
                                                                                                        {model.Completed_Comment !== null && (<><FontAwesomeIcon icon={faMessage} className='mr-10' /> {model.Completed_Comment}  </>)}
                                                                                                    </td>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-12"></div>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-block">
                                                    <div><small><u>Владелец:</u> {model.Name_User_Add}</small></div>
                                                    <div><small><u>Тип задачи:</u> {model.Name_Type}</small></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="main-card mb-3 card">
                                                <div className="card-header-tab card-header-tab-animation card-header">

                                                </div>
                                                <div className="card-body">
                                                    <div className="card-block-title">Расписание</div>

                                                    <div className="text-end mt-15 mb-10">
                                                        <div>
                                                            <button onClick={(e) => modalTimetableAddHandler(e)} className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary"><FontAwesomeIcon icon={faPlus} /> Добавить</button>
                                                            <ModalTaskRlTimetable show={modalTimetableShow} onHide={() => setModalTimetableShow(false)} id_taskR={model.ID} model_timetable={selectedModelTimetable} listTimetable={listTimetable} />
                                                            <ModalTaskRlTimetableRemove show={modalTimetableRemoveShow} onHide={() => setModalTimetableRemoveShow(false)} id_taskR={model.ID} model_timetable={selectedModelTimetable} listTimetable={listTimetable} />
                                                        </div>
                                                    </div>
                                                    <div className="div">
                                                        <ul className="todo-list-wrapper list-group list-group-flush">
                                                            {listTimetableIsLoading === true || listTimetableData === undefined ? (
                                                                <div className="loader_01"></div>
                                                            ) : (
                                                                listTimetableData.Data.map((model) => (
                                                                    <li key={model.ID} className="list-group-item row_select pl-5 pr-5 pt-5 pb-5">
                                                                        <div className="widget-content p-0">
                                                                            <div className="widget-content-wrapper">
                                                                                <div className="widget-content-left mr-2">
                                                                                    <i className="mr-5"><FontAwesomeIcon icon={faClock} /></i>
                                                                                </div>
                                                                                <div className="widget-content-left">
                                                                                    <div className="widget-heading">{model.Timetable_String}

                                                                                    </div>
                                                                                    {model.Day_Complite !== null && (
                                                                                        <div className="widget-subheading"><i>{model.Day_Complite} дн. для решения</i></div>
                                                                                    )}

                                                                                </div>
                                                                                <div className="widget-content-right widget-content-actions">
                                                                                    <button className="border-0 btn-transition btn btn-outline-seccondary btn-small-edit" onClick={(e) => timetableEditHandler(e, model)}>
                                                                                        <i className=""><FontAwesomeIcon icon={faEdit} /></i>
                                                                                    </button>
                                                                                    <button className="border-0 btn-transition btn btn-outline-seccondary btn-small-trash" onClick={(e) => timetableRemoveHandler(e, model)}>
                                                                                        <i className="" ><FontAwesomeIcon icon={faTrash} /></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            )
                                                            }
                                                        </ul>
                                                    </div>
                                                    {listParameterStatus !== 'fulfilled' ? (
                                                        <div>
                                                            <div className="loader_01"></div>
                                                        </div>
                                                    ) :
                                                        <Parameters list={parameterState.data.filter(a => a.ID_Display === 11)} dispatch={parameterDispatch} />
                                                    }
                                                </div>
                                                <div className="card-footer d-block">
                                                    {log != null && (
                                                        <div className="form_error text-center">
                                                            {log}
                                                        </div>
                                                    )}
                                                    {edit === true ?
                                                        (
                                                            <button style={{ width: "100%" }} className="btn btn-secondary" disabled={loadingSave} form="my-form" type="submit">
                                                                <FontAwesomeIcon icon={faSave} className="mr-5" />
                                                                {loadingSave === true ? ('Сохранение...') : 'Сохранить изменения'}
                                                            </button>
                                                        ) :
                                                        (
                                                            <button style={{ width: "100%" }} className="btn btn-secondary" disabled={loadingSave} form="my-form" type="submit">
                                                                <FontAwesomeIcon icon={faPlus} className="mr-5" />
                                                                {loadingSave === true ? ('Загрузка...') : 'Добавить задачу'}
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>

            )}

        </>
    );
}
export default ModalTaskR;
