import { createSlice, current } from '@reduxjs/toolkit'

//Сформирует фильтры по id_status_tab
function FilterGet(id_status_tab, data) {
    var mass_1 = [];

    mass_1.push({ id: 0, name: 'Все проекты', count: data.filter(a => a.ID_Status_Tab === id_status_tab).length, id_status_tab: id_status_tab, selected: true });

    data.map(item => {
        if (mass_1.filter(a => a.id === item.ID_Project & a.id_status_tab === id_status_tab).length === 0) {
            mass_1.push({ id: item.ID_Project, name: item.Name_Project, id_status_tab: item.ID_Status_Tab, count: data.filter(a => a.ID_Project === item.ID_Project).length, selected: false });
        }
    });

    return mass_1;
}
//Сформирует все фильтры
function FilterGetAll(data) {
    var mass_1 = [];

    data.map(item => {
        if (mass_1.filter(a => a.id === item.ID_Project & a.id_status_tab === item.ID_Status_Tab).length === 0) {
            mass_1.push({ id: item.ID_Project, name: item.Name_Project, id_status_tab: item.ID_Status_Tab, count: data.filter(a => a.ID_Project === item.ID_Project & a.ID_Status_Tab === item.ID_Status_Tab).length, selected: false });
        }
    });

    return mass_1;
}

const taskList = createSlice({
    name: 'taskList',
    initialState: {
        listAll: [] //Список всех задач без фильтров. НЕ ИЗМЕНЯТЬ
        , list: []//Список задач для вывода
        , listFilter: []//Список фильтров для вывода
    },
    reducers: {
        load(state, action) {
            const id_status_tab = action.payload.id_status_tab;   //1-Черновик; 2-Исполнитель; 3-Наблюдатель; 4-Будущая; 5-Выполненная

            const oldList = current(state.listAll);
            const oldListFilter = current(state.listFilter);

            const newList = action.payload.list.map(item => {
                return {
                    ...item,
                    ID_Status_Tab: id_status_tab //Добавляем служебный столбец
                };
            });

            //Обновляем коллекции
            state.list = [...oldList.filter(a => a.ID_Status_Tab !== id_status_tab), ...newList];
            state.listAll = [...oldList.filter(a => a.ID_Status_Tab !== id_status_tab), ...newList];
            state.listFilter = [...oldListFilter.filter(a => a.id_status_tab !== id_status_tab), ...FilterGet(id_status_tab, newList)];            
        },
        //Изменит задачу
        update(state, action) {            
            const id_status_tab = parseInt(action.payload.id_status_tab);   //1-Черновик; 2-Исполнитель; 3-Наблюдатель; 4-Будущая; 5-Выполненная
            const task = { ...action.payload.task, ID_Status_Tab: id_status_tab };

            state.list = [...state.list.filter(a => a.ID != task.ID), task];
            state.listAll = [...state.listAll.filter(a => a.ID != task.ID), task];
            state.listFilter = FilterGetAll([...state.listAll.filter(a => a.ID != task.ID), task]);            
        },
        remove(state, action) {

        },
        //Фильтр по проектам
        filterProject(state, action) {
            const id_status_tab = action.payload.id_status_tab;   //1-Черновик; 2-Исполнитель; 3-Наблюдатель; 4-Будущая; 5-Выполненная
            const id_project = action.payload.id_project;

            //Общая логика:
            //1)первым массивом добавляем все, кроме текущей вкладки
            //2)вторым массивом добавляем отфильтрованную логику

            //Фильтруем список
            if (id_project === 0) {
                state.list = [...state.list.filter(a => a.ID_Status_Tab !== id_status_tab), ...state.listAll.filter(a => a.ID_Status_Tab === id_status_tab)];
            }
            else {
                state.list = [...state.list.filter(a => a.ID_Status_Tab !== id_status_tab), ...state.listAll.filter(a => a.ID_Project === id_project & a.ID_Status_Tab === id_status_tab)];
            }

            //Делаем выбранным фильтр
            state.listFilter = [...state.listFilter.filter(a => a.id_status_tab !== id_status_tab), ...state.listFilter.filter(a => a.id_status_tab === id_status_tab).map(item => {
                if (item.id_status_tab === id_status_tab & item.id === id_project) {
                    return {
                        ...item,
                        selected: true
                    }
                }
                else {
                    return {
                        ...item,
                        selected: false
                    }
                }
            })];
        }
    }
})

export const { load, filterProject, update } = taskList.actions
export default taskList.reducer

