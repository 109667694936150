import { faEdit, faSave, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDisplayListByProjectMutation, useProjectDisplayUpdateMassMutation } from "../../../api/tracker/parameter";
import DesignerParamModalRemove from "./DesignerParamModalRemove";
import DesignerParamModalChangeList from "./DesignerParamModalChangeList";
import { useDispatch, useSelector } from "react-redux";
import { load as reducerLoad, orderChange as reducerOrderChange } from '../../../reducers/models/parameterDesignerTaskList'
import DesignerParamItem from "./DesignerParamItem";

function DesignerTaskList(props) {
    const { show, onHide, id_project } = props;

    const dispatch = useDispatch();

    const [listApi, { status: listApiStatus }] = useDisplayListByProjectMutation();
    const [updateMass] = useProjectDisplayUpdateMassMutation();

    const stateList = useSelector((state) => state.parameterDesignerTaskList)

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setError(null);
            setLoading(false);
            listApi({ ID_Project: id_project })
                .unwrap()
                .then(res => {
                    dispatch(reducerLoad({ data: res.Data }));
                })
        }
    }, [show]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    //ИЗМЕНЕНИЕ СОРТИРОВКИ
    function moveParam(dragIndex, hoverIndex, id_display) {
        dispatch(reducerOrderChange({ dragIndex: dragIndex, hoverIndex: hoverIndex, id_display: id_display }));
    }

    //СОХРАНЕНИЕ ИЗМЕНЕНИЙ

    function save() {
        setLoading(true);
        updateMass(stateList.list)
            .unwrap()
            .then(res1 => {
                setLoading(false);
                onHide();
            })
            .catch(error => {

            });
    }


    //МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ ПАРАМЕТРА ИЗ ОБЛАСТИ ОТОБРАЖЕНИЯ
    const [modalParamProjectDisplayRemoveShow, setModalParamProjectDisplayRemoveShow] = useState(false);
    const [selectedParam, setSelectedParam] = useState(null);

    function paramProjectDisplayRemoveModal(id_display, id_parameter) {
        setSelectedParam(stateList.list.filter(a => a.ID_Display === id_display & a.ID_Parameter === id_parameter));
        setModalParamProjectDisplayRemoveShow(true);
    }

    //МОДАЛЬНОЕ ОКНО ИЗМЕНЕНИЯ СПИСКА ПАРАМЕТРОВ
    const [modalParamProjectDisplayChangeListShow, setModalParamProjectDisplayChangeListShow] = useState(false);
    const [dataDisplay, setDataDisplay] = useState(null);

    function paramProjectDisplayChangeListModal(id_display) {
        setDataDisplay({ id_display: id_display, id_project: id_project });
        setModalParamProjectDisplayChangeListShow(true);
    }


    return (
        <>
            <DesignerParamModalRemove show={modalParamProjectDisplayRemoveShow} onHide={() => setModalParamProjectDisplayRemoveShow(false)} selectedParam={selectedParam} />
            <DesignerParamModalChangeList show={modalParamProjectDisplayChangeListShow} onHide={() => setModalParamProjectDisplayChangeListShow(false)} data={dataDisplay} />

            <Modal show={show} onHide={onHide} dialogClassName="modal-80">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faWandMagicSparkles} /></i> Дизайнер задачи
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" >
                        <div className="col-md-12">
                            <div className="table-responsive  mb-30 mt-30">
                                <table className="table">
                                    <tbody>
                                        <tr className='' >
                                            <td width={50}>
                                                <span className="badge badge-primary badge-dot badge-dot-lg"> </span>

                                            </td>
                                            <td><div className='task_list_name'>{'# 123 Тестовая задача'}</div>
                                                Проект №1<br></br>
                                                <small>01.01.2001 00:01</small>
                                            </td>
                                            <td>

                                            </td>
                                            <td>
                                                {
                                                    listApiStatus != 'fulfilled' ? <div className="loader_01"></div>
                                                        :
                                                        <div className="row ml-0 mr-0">
                                                            {stateList.list.filter(a => a.ID_Display === 7).map((model, index) => (
                                                                <DesignerParamItem key={model.ID_Parameter} index={index} model={model} moveParam={moveParam} paramProjectDisplayRemove={paramProjectDisplayRemoveModal} />
                                                            ))}
                                                        </div>
                                                }
                                            </td>
                                            <td className='text-end'>
                                                <button
                                                    className="btn-square btn-hover-shine btn btn-secondary"
                                                    disabled={true}
                                                >

                                                    <FontAwesomeIcon icon={faEdit} />

                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}
                    <button
                        className="btn-square btn-hover-shine btn btn-sm btn-secondary"
                        onClick={() => save()}
                        disabled={loading}
                    >
                        {loading === true ? (
                            "Сохранение..."
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faSave} className="mr-5" /> Сохранить изминения
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DesignerTaskList;
