import { faPenToSquare, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useListByDisplayMutation, useProjectDisplaySetMassMutation } from "../../../api/tracker/parameter";
import { useDispatch } from "react-redux";
import { add as reducerAdd } from '../../../reducers/models/parameterDesignerTaskList'


function DesignerParamModalChangeList(props) {
    const { show, onHide, data, listParam } = props;

    const dispatch = useDispatch();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paramsSelected, setParamsSelected] = useState([]);
    const [paramsCount, setParamsCount] = useState(null);


    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setError(null);
            setParamsCount(null);
            setLoading(false)
            setParamsSelected([]);
            paramsApi({ ID_Project: data.id_project, ID_Display: data.id_display })
        }
    }, [show]);

    //ПОДГРУЗКА СПИСКА
    const [paramsApi, { data: paramsApiData, status: paramsApiStatus }] = useListByDisplayMutation();

    useEffect(() => {
        if (paramsApiStatus === 'fulfilled') {
            if (paramsApiData.Status == 1) {
                setParamsCount(paramsApiData.Data.filter(a => a.Selected === false).length)
            }
            else {
                setError(paramsApiData.Error);
                setLoading(false);
            }
        }
        if (paramsApiStatus === 'rejected') {
            setError('Неизвестная ошибка!');
            setLoading(false);
        }
    }, [paramsApiStatus]);

    //ВЫБОР ПАРАМЕТРА
    function selectHandler(e, model) {
        if (e.target.checked === true) {
            console.log(model)
            setParamsSelected([
                ...paramsSelected,
                { ID_Project: data.id_project, ID_Parameter: model.ID_Parameter, Name_Parameter: model.Name, ID_Display: data.id_display, Name_Display: null, ID_Type: model.ID_Type, Order: 0, Width: 100, Read_Only: false, Checked: true }
            ]);
        }
        else {
            setParamsSelected(
                paramsSelected.filter(a => a.ID_Parameter !== model.ID_Parameter)
            );
        }
    }

    //ДОБАВЛЕНИЕ ПАРАМЕТРОВ В БД
    const [addApi] = useProjectDisplaySetMassMutation();

    function addHandler() {
        setLoading(true);

        addApi(paramsSelected)
            .unwrap()
            .then(res => {
                if (res.Status === 1) {
                    dispatch(reducerAdd({ data: paramsSelected }));
                    onHide();
                }
                else {
                    setError(res.Error);
                    setLoading(false);
                }
            })
            .catch(error => {
                setError('Неизвестная ошибка!');
                setLoading(false);
            })
    }

    return (
        <>
            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div><i className="mr-5"><FontAwesomeIcon icon={faPenToSquare} /></i> Изменение списка параметров</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {paramsCount === 0 && (<div className="text-center fs-5">Нет параметров в проекте. <br></br> Сначала добавьте параметры в проект</div>)}
                    {paramsApiStatus !== 'fulfilled' ? <div className="loader_01"></div>
                        :
                        (
                            paramsApiData.Data.filter(a => a.Selected === false).map((model) => (
                                <div key={model.ID_Parameter} className="d-flex align-items-center row_select">
                                    <div className="p-2 flex-grow-1">
                                        <b>{model.Name}</b><br></br>
                                        <label>{model.Name_Type}</label>
                                    </div>
                                    <div className="flex-grow-2 mr-30">

                                    </div>
                                    <div className="mr-5">
                                        <div className="form-check form-switch" >
                                            <div className="d-inline-flex flex-row-reverse gap-1 mt-5">
                                                <input className="form-check-input ms-0" defaultChecked={model.Selected} type="checkbox" role="switch" onChange={(e) => selectHandler(e, model)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}
                    {loading === true &&
                        (<div className="loader_01"></div>)
                    }
                    <button
                        className="btn-square btn-hover-shine btn btn-secondary"
                        type="submit"
                        onClick={() => addHandler()}
                        disabled={loading}                    >
                        {loading ? (
                            "Загрузка..."
                        ) : (
                            <div><FontAwesomeIcon icon={faPlus} className="mr-5" /> Добавить параметры</div>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default DesignerParamModalChangeList;
