import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useProjectListByOwnerParameterMutation, useProjectRemoveMutation } from "../../../api/tracker/parameter";
import { remove as reducerRemove } from '../../../reducers/models/parameterProjectList'
import { useDispatch } from "react-redux";
import { load as reducerLoad } from '../../../reducers/models/parameterProjectList'

function ModalParameterProjectRemove(props) {
    const { show, onHide, id_parameter, model_project, list_pproject_update, id_owner } = props;

    const dispatch = useDispatch()

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setError(null);
        }
    }, [show]);

    //ПЕРЕМЕННЫЕ
    const [loadingSave, setLoadingSave] = useState(false);
    const [error, setError] = useState(null);

    const [projectRemove, { data: projectRemoveData, status: projectRemoveStatus }] = useProjectRemoveMutation();
    const [apiProjectList] = useProjectListByOwnerParameterMutation();//Список проектов типа параметра(Задача, Регулярная задача, Доска)


    function handlerRemove() {
        setLoadingSave(true);
        projectRemove({ ID_Parameter: id_parameter, ID_Project: model_project.ID })
            .unwrap()
            .then(res => {
                apiProjectList({ ID_Owner_Parameter: id_owner })
                    .unwrap()
                    .then(res => {
                        dispatch(reducerLoad({ data: res.Data, id_owner: id_owner }));
                        //console.log('remove id_owner', id_owner)
                        //console.log('remove res', res)
                    });
            });
    }
    useEffect(() => {
        if (projectRemoveStatus === 'fulfilled') {
            if (projectRemoveData.Status == 1) {
                onHide()
                list_pproject_update({ ID_Parameter: id_parameter })
            }
            else {
                setError(projectRemoveData.Error)
            }
            setLoadingSave(false);
        }
        if (projectRemoveStatus === 'rejected') {

            setError('Неизвестная ошибка!');
            setLoadingSave(false);
        }
    }, [projectRemoveStatus]);

    return (
        <>
            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div><i className="mr-5"><FontAwesomeIcon icon={faTrash} /></i> Удаление проекта</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Подтвердите удаление проекта:<br></br> <u>«{model_project.Name}»</u>
                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}
                    <button
                        className="btn-square btn-hover-shine btn btn-secondary"
                        type="submit"
                        onClick={() => handlerRemove()}
                        disabled={loadingSave}
                    >
                        {loadingSave ? (
                            "Загрузка..."
                        ) : (
                            <div><FontAwesomeIcon icon={faCheck} className="mr-5" /> Подтвердить</div>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalParameterProjectRemove;
