import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useRemoveMutation } from "../../../api/tracker/project";


function ModalRemove(props) {
    const { show, onHide } = props;
    const { ID, Name, Description } = props.model;

    const [erros, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //Событие закрытия/открытия модального окна.
    useEffect(() => {
        if (show === true) {
            //Очистка форм
            setError(null);
            setLoading(false);
        }
    }, [show]);


    //API
    const [remove, { isError: updateIsError, isFetching: updateIsFetching, isLoading: updateIsLoading, data: updateData, status: updateStatus }] = useRemoveMutation();

    useEffect(() => {
        if (updateStatus === 'fulfilled') {
            if (updateData.Status == 1) {
                onHide();
                setLoading(false);
            }
            else {
                setError(updateData.Error);
                setLoading(false);
            }
        }
        if (updateStatus === 'rejected') {
            setError('Неизвестная ошибка!');
            setLoading(false);
        }

    }, [updateStatus]);



    //Отправка формы
    const submit = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        remove({ 'ID': ID });        
    };



    return (
        <>
            <Modal show={show} onHide={onHide} size="md">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <FontAwesomeIcon icon={faTrash} /> Удаление компании
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="my-form" onSubmit={submit}>
                        <input name='ID' defaultValue={ID} hidden></input>
                        Подтвердите удаление компании.
                    </Form>  
                    {erros != null && (
                        <div className="form_error text-center">
                            {erros}
                        </div>
                    )}                 
                </Modal.Body>
                <Modal.Footer>
                    <button className="mr-5 btn-square btn-hover-shine btn btn-secondary" onClick={onHide}>
                        <FontAwesomeIcon icon={faXmark} className="mr-5" />
                        Закрыть
                    </button>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        form="my-form"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? (
                            "Удаление..."
                        ) :
                            (
                                <>
                                    <FontAwesomeIcon icon={faTrash} className="mr-5" />Удалить
                                </>
                            )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalRemove;
