import { createSlice, current } from '@reduxjs/toolkit'

const parameterList = createSlice({
    name: 'parameterList',
    initialState: {
        listAll: [], //Список всех элементов без фильтров.
        list: [],  //Список  элементов для вывода
        // listFilter: []//Список фильтров
    },
    reducers: {
        load(state, action) {
            const id_owner = action.payload.id_owner; //1-Задача; 2-Регулярная задача; 3-Доска; 4-Регулярная доска

            //Обновляем коллекции
            state.list = [...state.list.filter(a => a.ID_Owner !== id_owner), ...action.payload.data];
            state.listAll = [...state.listAll.filter(a => a.ID_Owner !== id_owner), ...action.payload.data];
        },
        add(state, action) {
            state.list = [...state.list, action.payload.model];
            state.listAll = [...state.listAll, action.payload.model];
        },
        update(state, action) {
            state.list = state.list.filter(a => a.ID !== action.payload.model.ID);
            state.list = [...state.list, action.payload.model];
            state.listAll = state.listAll.filter(a => a.ID !== action.payload.model.ID);
            state.listAll = [...state.listAll, action.payload.model];
        },
        remove(state, action) {
            const id = action.payload.id;
            state.list = state.list.filter(a => a.ID !== id);
            state.listAll = state.listAll.filter(a => a.ID !== id);
        },
        filter(state, action) {
            const list_data = action.payload.list_data;
            const id_project = action.payload.id_project;

            if (id_project === 0) {
                state.list = state.listAll;
            }
            else {
                var new_list = [];

                state.listAll.map(item => {
                    if (list_data.filter(a => a.ID_Parameter === item.ID).length > 0) {
                        new_list.push(item);
                    }
                });

                state.list = new_list;
            }
        }
    }
})

export const { load, add, update, remove, filter } = parameterList.actions
export default parameterList.reducer