import { createSlice } from '@reduxjs/toolkit'

function FilterGenerate(data, id_owner) {
    var mass_1 = [];

    // mass_1.push({ id: 0, name: 'Все проекты', count: data.filter(a => a.ID_Owner_Parameter === id_owner).length, selected: true, id_owner: id_owner });
    mass_1.push({ id: 0, name: 'Все проекты', count: null, selected: true, id_owner: id_owner });

    data.map(item => {
        if (mass_1.filter(a => a.id === item.ID_Project & a.id_owner === id_owner).length === 0) {
            mass_1.push({ id: item.ID_Project, name: item.Name_Project, count: data.filter(a => a.ID_Owner_Parameter === id_owner & a.ID_Project === item.ID_Project).length, selected: false, id_owner: id_owner });
        }
    });

    return mass_1;
}

function OptionsGenerate(data, id_owner) {
    var mass_1 = [];
/*
                    value: listProjectData.Data[i].ID,
                    label: listProjectData.Data[i].Name,
                    checked: listProjectData.Data[i].Checked
*/

    data.map(item => {
        if (mass_1.filter(a => a.id === item.ID_Project & a.id_owner === id_owner).length === 0) {
            mass_1.push({ value: item.ID_Project, label: item.Name_Project, checked: false });
        }
    });

    return mass_1;
}

const parameterProjectList = createSlice({
    name: 'parameterProjectList',
    initialState: {
        list: [],  //Список  элементов для вывода
        listFilter: [],//Список фильтров
        listOptions: []//Список для элемента select
    },
    reducers: {
        load(state, action) {
            const id_owner = action.payload.id_owner; //1-Задача; 2-Регулярная задача; 3-Доска; 4-Регулярная доска

            state.list = [...state.list.filter(a => a.ID_Owner_Parameter !== id_owner), ...action.payload.data];
            state.listFilter = [...state.listFilter.filter(a => a.id_owner !== id_owner), ...FilterGenerate(action.payload.data, id_owner)];
            state.listOptions = [...state.listOptions.filter(a => a.id_owner !== id_owner), ...OptionsGenerate(action.payload.data, id_owner)];
        },

        update(state, action) {
            state.list = state.list.filter(a => a.ID !== action.payload.model.ID);
            state.list = [...state.list, action.payload.model];
        },

        filter(state, action) {
            const id_project = action.payload.id_project;

            state.listFilter = state.listFilter.map((model, i) => {
                if (model.id === id_project) {
                    return { ...model, selected: true };
                }
                else {
                    return { ...model, selected: false };
                }
            });
        }
    }
})

export const { load, add, update, remove, filter } = parameterProjectList.actions
export default parameterProjectList.reducer