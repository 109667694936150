import { faArchive, faPlay, faPlus, faSpinner, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from 'reactstrap';
import { useGetTempMutation, useListMutation, useProjectDisplayListByOwnerParameterMutation, useProjectListByOwnerParameterMutation } from "../../../api/tracker/parameter";
import ModalParameter from "./ModalParameter";
import List from "./List";
import DesignerProjectModal from "./DesignerProjectModal";
import { useDispatch, useSelector } from "react-redux";
import { load, filter } from '../../../reducers/models/parameterList'
import { load as loadProject, filter as filterProject } from '../../../reducers/models/parameterProjectList'
import { load as loadProjectDisplay } from '../../../reducers/models/parameterProjectDisplayList'
import FilterProject from "../../General/FilterProject";

function Index({ id_owner }) {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);

  //Получаем данные из reducer
  const stateParameter = useSelector((state) => state.parameterList)
  const stateParameterProject = useSelector((state) => state.parameterProjectList)

  //START ЗАГРУЗКА ДАННЫХ В REDUCER
  const [apiList] = useListMutation();
  const [apiProjectList] = useProjectListByOwnerParameterMutation();//Список проектов типа параметра(Задача, Регулярная задача, Доска)
  const [apiListProjectDisplayList] = useProjectDisplayListByOwnerParameterMutation();

  useEffect(() => {
    //Если данных нет, то загружаем
    if (stateParameter.listAll.filter(a => a.ID_Owner === id_owner).length === 0) {
      setLoading(true);
      //Загружаем параметры
      apiList({ ID_Owner: id_owner })
        .unwrap()
        .then(res => {
          dispatch(load({ data: res.Data, id_owner: id_owner }))
          setLoading(false);
        });
      //Загружаем проекты
      apiProjectList({ ID_Owner_Parameter: id_owner })
        .unwrap()
        .then(res => {
          dispatch(loadProject({ data: res.Data, id_owner: id_owner }))
        });
      //Загружаем области отображения
      apiListProjectDisplayList({ ID_Owner_Parameter: id_owner })
        .unwrap()
        .then(res => {
          dispatch(loadProjectDisplay({ data: res.Data, id_owner: id_owner }))
        });
    }
  }, [id_owner]);
  //END ЗАГРУЗКА ДАННЫХ В REDUCER

  //START TAB 
  const [tab1Show, setTab1Show] = useState(true);
  const [tab2Show, setTab2Show] = useState(false);

  function tabHandleClick(e) {
    if (e.currentTarget.id === "tab_1") {
      setTab1Show(true);
      setTab2Show(false);
    }
    if (e.currentTarget.id === "tab_2") {
      setTab1Show(false);
      setTab2Show(true);
    }
  }
  //END TAB
  //МОДАЛЬНЫЕ ОКНА
  const [modalDesignerShow, setModalDesignerShow] = useState(false);


  /*******************START ДОБАВЛЕНИЕ ПАРАМЕТРА***************************/
  //Получаем временную запись для добавления параметра
  const [getTemp] = useGetTempMutation();

  //ПЕРЕМЕННЫЕ
  const [modelParam, setModelParam] = useState({}); //Модель параметра для передачи в модальное окно ModalParameter
  const [loadingAddTask, setLoadingAddTask] = useState(null);//Индикатор загрузки
  const [modalParameterShow, setModalParameterShow] = useState(false);//Модальное окно

  function modalAddHandler() {
    setLoadingAddTask(true);
    getTemp({ ID_Owner: id_owner })
      .unwrap()
      .then(res => {
        setModelParam(res.Data);
        setModalParameterShow(true);
        setLoadingAddTask(false);
      });
  }
  /********************END ДОБАВЛЕНИЕ ПАРАМЕТРА****************************/

  function filterHandlerProject(id) {
    dispatch(filterProject({ id_project: id }));
    dispatch(filter({ id_project: id, list_data: stateParameterProject.list.filter(a => a.ID_Project === id) }));
  }


  return (
    <>
      <ModalParameter show={modalParameterShow} onHide={() => setModalParameterShow(false)} model={modelParam} id_owner={id_owner} />
      <DesignerProjectModal show={modalDesignerShow} onHide={() => setModalDesignerShow(false)} id_owner={id_owner} />

      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header-tab card-header-tab-animation card-header">
              <div className="card-header-title">
                <Button onClick={() => modalAddHandler()} color="secondary"><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i>Добавить параметр</Button>
                <Button onClick={() => setModalDesignerShow(true)} color="secondary" className="ml-15"><i className="mr-5"><FontAwesomeIcon icon={faWandMagicSparkles} /></i>Дизайнер</Button>

                {loadingAddTask === true && <FontAwesomeIcon className="ml-10" icon={faSpinner} size="2x" />}
              </div>
              <ul className="nav">
                <li className="nav-item">
                  <a
                    id="tab_1"
                    onClick={tabHandleClick}
                    data-toggle="tab"
                    className={tab1Show ? "nav-link active show" : "nav-link show"}
                  >
                    <FontAwesomeIcon icon={faPlay} /> Активные
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="tab_2"
                    onClick={tabHandleClick}
                    data-toggle="tab"
                    className={tab2Show ? "nav-link active show" : "nav-link show"}
                  >
                    <FontAwesomeIcon icon={faArchive} /> Архив
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content">
                <div>
                  <FilterProject key={1} id_filter={1} list={stateParameterProject.listFilter.filter(a => a.id_owner === id_owner)} clickHandler={filterHandlerProject} show_all={true} show_count={true} />
                </div>
                <div className={tab1Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                  <div className="">
                    {loading ? <div className="loader_01"></div> : <List list={stateParameter.list.filter(a => a.ID_Owner === id_owner & a.ID_Status === 1)} />}
                  </div>
                </div>
                <div className={tab2Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                  <div className="">
                    {loading ? <div className="loader_01"></div> : <List list={stateParameter.list.filter(a => a.ID_Owner === id_owner & a.ID_Status === 0)} />}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
