import { createSlice, current } from '@reduxjs/toolkit'
import immutabilityUpdate from 'immutability-helper'

const parameterDesignerTaskList = createSlice({
    name: 'parameterDesignerTaskList',
    initialState: {
        list: []
    },
    reducers: {
        //Загрузит список параметров
        load(state, action) {
            state.list = action.payload.data;
        },
        //Изменит сортировку параметра
        orderChange(state, action) {
            const dragIndex = action.payload.dragIndex;//Первоначальный индекс элемента
            const hoverIndex = action.payload.hoverIndex;//Индекс куда перемещен элемент
            const id_display = action.payload.id_display;//Область отображения

            const list = current(state.list).filter(a => a.ID_Display === id_display)

            const list_new = immutabilityUpdate(list, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, list[dragIndex]],
                ],
            })

            const list_new_order = list_new.map((item, index) => {
                return { ...item, Order: index };
            });

            state.list = [...state.list.filter(a => a.ID_Display !== id_display), ...list_new_order];
        },
        //Изменит ширину параметра
        orderWidth(state, action) {
            const model = action.payload.model;//Модель параметра
            const width = action.payload.width;//Ширина параметра

            const list_new = state.list.map((item, index) => {
                if (item.ID_Parameter === model.ID_Parameter & item.ID_Display === model.ID_Display & item.ID_Project === model.ID_Project) {
                    return { ...item, Width: width };
                }
                else {
                    return item;
                }
            });

            state.list = list_new;
        },
        remove(state, action) {
            const id_parameter = action.payload.id_parameter;//ИД параметра
            const id_project = action.payload.id_project;//ИД проекта
            const id_display = action.payload.id_display;//Область отображения

            state.list = state.list.filter(a => a.ID_Display !== id_display || a.ID_Parameter !== id_parameter || a.ID_Project !== id_project);
        },
        add(state, action) {
            state.list = [...state.list, ...action.payload.data]
        },

    }
})

export const { load, orderChange, orderWidth, remove, add } = parameterDesignerTaskList.actions
export default parameterDesignerTaskList.reducer