import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import StringToDate from '../../../../util/StringToDate';
import { faCalendar, faFlagCheckered, faHourglassEnd, faMessage } from '@fortawesome/free-solid-svg-icons';
import { differenceInDays } from 'date-fns';
import StringToDateTime from '../../../../util/StringToDateTime';


function ListCompleted({ list }) {

    function DateStartEnd({ date_start, date_end, date_completed }) {
        if (date_start !== null & date_end !== null) {

            const diff_days = differenceInDays(
                new Date(new Date()),
                new Date(date_completed)
            )
            return (<>
                <FontAwesomeIcon icon={faFlagCheckered} className='mr-10' /> {StringToDate(date_completed)}г  {diff_days > 0 && <small> (д.б. {StringToDate(date_end)}г)</small>}
                {diff_days > 0 && <div className='text-danger'><b><FontAwesomeIcon icon={faHourglassEnd} className='mr-10' /> Просрочено {diff_days} дн.</b></div>}
            </>)
        }
        else if (date_start !== null & date_end === null) {
            return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' />С {StringToDate(date_start)}</>)
        }
        else {
            return null;
        }
    }  
    return (

        < div className="table-responsive" >
            <table className="table">
                <tbody>
                    {list.map((model) => (
                        <tr className='row_select' key={model.ID}>
                            <td>
                                <div className='task_list_name'>{model.Name_Task}</div>
                                <small>{model.Name_Project}</small><br></br>
                                <small>{StringToDateTime(model.Date)}</small>
                            </td>
                            <td>{model.User_Name}</td>
                            <td><DateStartEnd date_start={model.Date} date_end={model.Date_End} date_completed={model.Completed_Date} /></td>

                            <td>
                                {model.Timetable_String} <br></br>
                                <small>{model.Name_Type_Task}</small>
                            </td>
                            <td>
                                {model.Completed_Comment !== null && (<><FontAwesomeIcon icon={faMessage} className='mr-10' /> {model.Completed_Comment}  </>)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >

    );
}

export default ListCompleted;