import { faComment, faDatabase, faFloppyDisk, faFolder, faHouse, faPenToSquare, faPlus, faSave, faUserPen, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import SelectSearch from "../../../components/ui/SelectSearch";
import { Editor } from 'react-draft-wysiwyg';
import { useDisplayListByProjectMutation, useProjectDisplayUpdateMassMutation } from "../../../api/tracker/parameter";
import DesignerParamItem from "./DesignerParamItem";
import DesignerParamModalRemove from "./DesignerParamModalRemove";
import DesignerParamModalChangeList from "./DesignerParamModalChangeList";
import { useDispatch, useSelector } from "react-redux";
import { load as reducerLoad, orderChange as reducerOrderChange } from '../../../reducers/models/parameterDesignerTaskList'

function DesignerTask(props) {
    const { show, onHide, id_project } = props;

    const dispatch = useDispatch();

    const [listApi, { status: listApiStatus }] = useDisplayListByProjectMutation();
    const [updateMass] = useProjectDisplayUpdateMassMutation();

    const stateList = useSelector((state) => state.parameterDesignerTaskList)

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setError(null);
            setLoading(false);
            listApi({ ID_Project: id_project })
                .unwrap()
                .then(res => {
                    dispatch(reducerLoad({ data: res.Data }));
                })
        }
    }, [show]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    //TAB
    const [tab1Show, setTab1Show] = useState(true);
    const [tab2Show, setTab2Show] = useState(false);
    const [tab3Show, setTab3Show] = useState(false);

    function tabHandleClick(e) {
        if (e.currentTarget.id === "tab_1") {
            setTab1Show(true);
            setTab2Show(false);
            setTab3Show(false);
        }
        if (e.currentTarget.id === "tab_2") {
            setTab1Show(false);
            setTab2Show(true);
            setTab3Show(false);
        }
        if (e.currentTarget.id === "tab_3") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(true);
        }
    }


    //ИЗМЕНЕНИЕ СОРТИРОВКИ
    function moveParam(dragIndex, hoverIndex, id_display) {
        dispatch(reducerOrderChange({ dragIndex: dragIndex, hoverIndex: hoverIndex, id_display: id_display }));
    }

    //СОХРАНЕНИЕ ИЗМЕНЕНИЙ

    function save() {
        setLoading(true);
        updateMass(stateList.list)
            .unwrap()
            .then(res1 => {
                setLoading(false);
                onHide();
            })
            .catch(error => {

            });
    }


    //МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ ПАРАМЕТРА ИЗ ОБЛАСТИ ОТОБРАЖЕНИЯ
    const [modalParamProjectDisplayRemoveShow, setModalParamProjectDisplayRemoveShow] = useState(false);
    const [selectedParam, setSelectedParam] = useState(null);

    function paramProjectDisplayRemoveModal(id_display, id_parameter) {
        setSelectedParam(stateList.list.filter(a => a.ID_Display === id_display & a.ID_Parameter === id_parameter));
        setModalParamProjectDisplayRemoveShow(true);
    }

    //МОДАЛЬНОЕ ОКНО ИЗМЕНЕНИЯ СПИСКА ПАРАМЕТРОВ
    const [modalParamProjectDisplayChangeListShow, setModalParamProjectDisplayChangeListShow] = useState(false);
    const [dataDisplay, setDataDisplay] = useState(null);

    function paramProjectDisplayChangeListModal(id_display) {
        setDataDisplay({ id_display: id_display, id_project: id_project });
        setModalParamProjectDisplayChangeListShow(true);
    }


    return (
        <>
            <DesignerParamModalRemove show={modalParamProjectDisplayRemoveShow} onHide={() => setModalParamProjectDisplayRemoveShow(false)} selectedParam={selectedParam} /*listParam={listParam} */ />
            <DesignerParamModalChangeList show={modalParamProjectDisplayChangeListShow} onHide={() => setModalParamProjectDisplayChangeListShow(false)} data={dataDisplay} /*listParam={listParam}*/ />

            <Modal show={show} onHide={onHide} dialogClassName="modal-80">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faWandMagicSparkles} /></i> Дизайнер задачи
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" >
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="main-card card">
                                        <div className="card-header-tab card-header-tab-animation card-header">
                                            <div className="card-header-title">
                                                В работе
                                            </div>
                                            <ul className="nav">
                                                <li className="nav-item">
                                                    <a
                                                        id="tab_1"
                                                        onClick={tabHandleClick}
                                                        data-toggle="tab"
                                                        className={tab1Show ? "nav-link active show" : "nav-link show"}
                                                    >
                                                        <FontAwesomeIcon icon={faHouse} /> Основное
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        id="tab_2"
                                                        onClick={tabHandleClick}
                                                        data-toggle="tab"
                                                        className={tab2Show ? "nav-link active show" : "nav-link show"}
                                                    >
                                                        <FontAwesomeIcon icon={faDatabase} /> Параметры
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        id="tab_3"
                                                        onClick={tabHandleClick}
                                                        data-toggle="tab"
                                                        className={tab3Show ? "nav-link active show" : "nav-link show"}
                                                    >
                                                        <FontAwesomeIcon icon={faFolder} /> Файлы
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-content">
                                                <div className={tab1Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div className="card-block-title">Основное</div>
                                                            <Form id="my-form" >
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label>Название задачи</Form.Label>
                                                                    <Form.Control name="Name" type="text" valid='[{"id": "empty"}]' disabled={true} />
                                                                </Form.Group>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                                                    <Form.Label>Проект</Form.Label>
                                                                    <SelectSearch name="ID_Project" placeholder='Выберите проект' className="z-index-max" disabled={true} />
                                                                </Form.Group>
                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                    <Form.Label>Описание</Form.Label>
                                                                    <Editor
                                                                        disabled={true}
                                                                        editorClassName={'editor-class-disabled'}
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                            <div className="text-center mb-20 mt-20">
                                                                <div>
                                                                    <button className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary" onClick={() => paramProjectDisplayChangeListModal(1)}><FontAwesomeIcon icon={faPenToSquare} /> Изменить список параметров</button>
                                                                </div>
                                                            </div>
                                                            {
                                                                listApiStatus != 'fulfilled' ? <div className="loader_01"></div>
                                                                    :
                                                                    <div className="row ml-0 mr-0">
                                                                        {stateList.list.filter(a => a.ID_Display === 1).map((model, index) => (
                                                                            <DesignerParamItem key={model.ID_Parameter} index={index} model={model} moveParam={moveParam} paramProjectDisplayRemove={paramProjectDisplayRemoveModal} />
                                                                        ))}
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="card-block-title">Приоритет</div>
                                                            <div className="text-center mt-15">
                                                                <button className='btn-pill btn btn-outline-primary'>Низкий</button>
                                                                <button className='ml-15 btn-pill btn btn-outline-secondary'>Нормальный</button>
                                                                <button className='ml-15 btn-pill btn btn-outline-danger'>Высокий</button>
                                                            </div>

                                                            <div className="text-center mb-20 mt-20">
                                                                <div>
                                                                    <button className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary" onClick={() => paramProjectDisplayChangeListModal(2)}><FontAwesomeIcon icon={faPenToSquare} /> Изменить список параметров</button>
                                                                </div>
                                                            </div>
                                                            {
                                                                listApiStatus != 'fulfilled' ? <div className="loader_01"></div>
                                                                    :
                                                                    <div className="row ml-0 mr-0">
                                                                        {stateList.list.filter(a => a.ID_Display === 2).map((model, index) => (
                                                                            <DesignerParamItem key={model.ID_Parameter} index={index} model={model} moveParam={moveParam} paramProjectDisplayRemove={paramProjectDisplayRemoveModal} />
                                                                        ))}
                                                                    </div>

                                                            }
                                                            <div className="card-block-title">Даты</div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Дата начала</Form.Label>
                                                                        <Form.Control name="Date_Start" type="date" disabled />
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Дата завершения</Form.Label>
                                                                        <Form.Control name="Date_End" type="date" disabled />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <div className="text-center mb-20 mt-20">
                                                                <div>
                                                                    <button className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary" onClick={() => paramProjectDisplayChangeListModal(5)}><FontAwesomeIcon icon={faPenToSquare} /> Изменить список параметров</button>
                                                                </div>
                                                            </div>
                                                            {
                                                                listApiStatus != 'fulfilled' ? <div className="loader_01"></div>
                                                                    :
                                                                    <div className="row ml-0 mr-0">
                                                                        {stateList.list.filter(a => a.ID_Display === 5).map((model, index) => (
                                                                            <DesignerParamItem key={model.ID_Parameter} index={index} model={model} moveParam={moveParam} paramProjectDisplayRemove={paramProjectDisplayRemoveModal} />
                                                                        ))}
                                                                    </div>

                                                            }
                                                            <div className="card-block-title">Пользователи</div>
                                                            <div className="text-end mt-15">
                                                                <div>
                                                                    <button disabled className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary"><FontAwesomeIcon icon={faUserPen} /> Изменить...</button>
                                                                </div>
                                                            </div>
                                                            <div className="div">
                                                                <div className="scrollbar-container ps ps--active-y">
                                                                    <div className="p-2">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-center mb-20 mt-20">
                                                                <div>
                                                                    <button className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary" onClick={() => paramProjectDisplayChangeListModal(3)}><FontAwesomeIcon icon={faPenToSquare} /> Изменить список параметров</button>
                                                                </div>
                                                            </div>
                                                            {
                                                                listApiStatus != 'fulfilled' ? <div className="loader_01"></div>
                                                                    :
                                                                    <div className="row ml-0 mr-0">
                                                                        {stateList.list.filter(a => a.ID_Display === 3).map((model, index) => (
                                                                            <DesignerParamItem key={model.ID_Parameter} index={index} model={model} moveParam={moveParam} paramProjectDisplayRemove={paramProjectDisplayRemoveModal} />
                                                                        ))}
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={tab2Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                                    <div className="text-center mb-20 mt-20">
                                                        <div>
                                                            <button className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary" onClick={() => paramProjectDisplayChangeListModal(6)}><FontAwesomeIcon icon={faPenToSquare} /> Изменить список параметров</button>
                                                        </div>
                                                    </div>
                                                    {
                                                        listApiStatus != 'fulfilled' ? <div className="loader_01"></div>
                                                            :
                                                            <div className="row ml-0 mr-0">

                                                                {stateList.list.filter(a => a.ID_Display === 6).map((model, index) => (
                                                                    <DesignerParamItem key={model.ID_Parameter} index={index} model={model} moveParam={moveParam} paramProjectDisplayRemove={paramProjectDisplayRemoveModal} />
                                                                ))}
                                                            </div>
                                                    }
                                                </div>
                                                <div className={tab3Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                                    <div className="">
                                                        3
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12"></div>
                                            </div>
                                        </div>
                                        <div className="card-footer  d-block">
                                            <div><small><u>Владелец:</u> </small></div>
                                            <div><small><u>Тип задачи:</u> </small></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="main-card mb-3 card">
                                        <div className="card-header-tab card-header-tab-animation card-header">

                                        </div>
                                        <div className="card-body">
                                            <div className="card-block-title">Действия</div>
                                            <button
                                                className="btn-square btn-hover-shine btn btn-secondary btn-width-100 mt-10"
                                                form="my-form"
                                                type="submit"
                                                disabled={true}
                                            >
                                                <FontAwesomeIcon icon={faFloppyDisk} className="mr-5" /> Сохранить
                                            </button>


                                            <div className="text-center mb-20 mt-20">
                                                <div>
                                                    <button className="mb-2 mr-2 btn btn-sm btn-dashed btn-outline-secondary" onClick={() => paramProjectDisplayChangeListModal(4)}><FontAwesomeIcon icon={faPenToSquare} /> Изменить список параметров</button>
                                                </div>
                                            </div>
                                            {
                                                listApiStatus != 'fulfilled' ? <div className="loader_01"></div>
                                                    :
                                                    <div className="row ml-0 mr-0">
                                                        {stateList.list.filter(a => a.ID_Display === 4).map((model, index) => (
                                                            <DesignerParamItem key={model.ID_Parameter} index={index} model={model} moveParam={moveParam} paramProjectDisplayRemove={paramProjectDisplayRemoveModal} />
                                                        ))}
                                                    </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}
                    <button
                        className="btn-square btn-hover-shine btn btn-sm btn-secondary"
                        onClick={() => save()}
                        disabled={loading}
                    >
                        {loading === true ? (
                            "Сохранение..."
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faSave} className="mr-5" /> Сохранить изминения
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DesignerTask;
