import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
//useRemoveMutation

import { useEffect, useState } from "react";
import { useRemoveMutation } from "../../../api/tracker/parameter";
import { remove as reducerRemove } from '../../../reducers/models/parameterList'
import { useDispatch } from "react-redux";

function ModalParameterRemove(props) {
    const { show, onHide, model } = props;

    const dispatch = useDispatch()

    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setError(null);
            setLoadingSave(false);
        }
    }, [show]);

    //ПЕРЕМЕННЫЕ
    const [loadingSave, setLoadingSave] = useState(false);
    const [error, setError] = useState(null);

    const [parameterRemove] = useRemoveMutation();

    function handlerRemove() {
        setLoadingSave(true);
        parameterRemove({ ID_Parameter: model.ID })
            .unwrap()
            .then(res => {
                if (res.Status == 1) {
                    //Удалит параметр из редюсере
                    dispatch(reducerRemove({ id: model.ID }));
                    onHide();
                    setLoadingSave(false);
                }
                else {
                    setError(res.Error);
                    setLoadingSave(false);
                }
            })
            .catch(res => {
                setError('Неизвестная ошибка');
                setLoadingSave(false);
            });
    }


    return (
        <>
            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div><i className="mr-5"><FontAwesomeIcon icon={faTrash} /></i> Удаление параметра</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Подтвердите удаление параметра: <b>«{model.Name}»</b>
                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}
                    <button
                        className="btn-square btn-hover-shine btn btn-secondary"
                        type="submit"
                        onClick={() => handlerRemove()}
                        disabled={loadingSave}
                    >
                        {loadingSave ? (
                            "Удаление..."
                        ) : (
                                <div><FontAwesomeIcon icon={faTrash} className="mr-5" /> Удалить</div>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalParameterRemove;