import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import ModalParameter from './ModalParameter';
import ModalParameterRemove from './ModalParameterRemove';

function List(props) {
    const { list } = props;

    //МОДАЛЬНЫЕ ОКНА
    const [modalParameterShow, setModalParameterShow] = useState(false);
    const [modalParameterRemoveShow, setModalParameterRemoveShow] = useState(false);

    const [modelParam, setModelParam] = useState({}); //Модель параметра для передачи в модальное окно ModalParameter

    function ModalEditShow(model) {
        setModelParam(model);
        setModalParameterShow(true);
    }
    function ModalRemoveShow(model) {
        setModelParam(model);
        setModalParameterRemoveShow(true);
    }

    return (
        <>
            <ModalParameter show={modalParameterShow} onHide={() => setModalParameterShow(false)} model={modelParam} />
            <ModalParameterRemove show={modalParameterRemoveShow} onHide={() => setModalParameterRemoveShow(false)} model={modelParam} />

            {list.sort((a, b) => a.ID - b.ID).map((model, index) => (
                <div key={model.ID} className='row row_select parameter_row'>
                    <div className='col-md-10'>
                        <div className='row '>
                            <div className='col-md-3'>
                                <h5>{model.Name}</h5>
                                <div>
                                    <i>Тип: {model.Name_Type}</i>
                                </div>
                            </div>
                            <div className='col-md-3 d-flex align-self-center'><i></i></div>
                            <div className='col-md-3 d-flex align-self-center'><i></i></div>
                            <div className='col-md-3'></div>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex justify-content-center justify-content-xl-end justify-content-md-center justify-content-sm-center align-self-center' >
                        <div>
                            <button className="btn-square btn-hover-shine btn btn-secondary" onClick={(e) => ModalEditShow(model)} >
                                <i><FontAwesomeIcon icon={faPenToSquare} /></i>
                            </button>
                            <button className="btn-square btn-hover-shine btn btn-secondary ml-10" onClick={(e) => ModalRemoveShow(model)}>
                                <i><FontAwesomeIcon icon={faTrash} /></i>
                            </button>
                        </div>

                    </div>
                </div>
            ))}
        </>
    )
}
export default List;
