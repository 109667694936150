import { faArchive, faCheck, faEye, faFlagCheckered, faPlus, faRocket, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import ModalAddInfo from "./ModalAddInfo";
import { useLazyGetTempQuery, useListActiveQuery, useLazyListCompletedQuery, useListDraftQuery, useListFutureQuery } from "../../../api/tracker/task";
import ModalTask from "./ModalTask";
import List from "./List";
import FilterProject from "../../General/FilterProject";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux'
import { load, filterProject } from '../../../reducers/models/taskList'

function Index() {

    const dispatch = useDispatch()

    //TAB START
    const [tab1Show, setTab1Show] = useState(false);
    const [tab2Show, setTab2Show] = useState(true);
    const [tab3Show, setTab3Show] = useState(false);
    const [tab4Show, setTab4Show] = useState(false);
    const [tab5Show, setTab5Show] = useState(false);

    function tabHandleClick(e) {
        if (e.currentTarget.id === "tab_1") {
            setTab1Show(true);
            setTab2Show(false);
            setTab3Show(false);
            setTab4Show(false);
            setTab5Show(false);
        }
        if (e.currentTarget.id === "tab_2") {
            setTab1Show(false);
            setTab2Show(true);
            setTab3Show(false);
            setTab4Show(false);
            setTab5Show(false);
        }
        if (e.currentTarget.id === "tab_3") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(true);
            setTab4Show(false);
            setTab5Show(false);
        }
        if (e.currentTarget.id === "tab_4") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(false);
            setTab4Show(true);
            setTab5Show(false);
        }
        if (e.currentTarget.id === "tab_5") {
            setTab1Show(false);
            setTab2Show(false);
            setTab3Show(false);
            setTab4Show(false);
            setTab5Show(true);
        }
    }
    //TAB END


    useEffect(() => {
        setFilterCompleteLoading(true);
        listCompleteApi();
    }, []);

    //START Модальные окна
    const [modalAddInfoShow, setModalAddInfoShow] = useState(false);
    const [modalTaskShow, setModalTaskShow] = useState(false);

    function modalInfoHandler() {
        setModalAddInfoShow(true);
    }
    //END Модальные окна

    const [logTaskChange, setLogTaskChange] = useState(null);
    const [loadingTaskChange, setLoadingTaskChange] = useState(false);

    const [getTempApi, getTempApiResult] = useLazyGetTempQuery();

    function taskChangeHandler(arg) {
        setLoadingTaskChange(true);
        getTempApi({ ID_Type: arg })
            .unwrap()
            .then(res => {
                setModalTaskShow(true);
                setLoadingTaskChange(false);
            });
    };

    /****************************START API СПИСОК ЗАДАЧ**********************/
    const listData = useSelector((state) => state.taskList)

    const [filterCompleteDateStart, setFilterCompleteDateStart] = useState();
    const [filterCompleteDateEnd, setFilterCompleteDateEnd] = useState();
    const [filterCompleteError, setFilterCompleteError] = useState(null);
    const [filterCompleteLoading, setFilterCompleteLoading] = useState(false);

    const listDraftApi = useListDraftQuery();
    const listActiveObserverApi = useListActiveQuery({ ID_Action_User: 1 });
    const listActiveExecutorApi = useListActiveQuery({ ID_Action_User: 2 });
    const listFutureApi = useListFutureQuery();
    const [listCompleteApi, listCompleteApiResult] = useLazyListCompletedQuery();

    useEffect(() => {
        if (listDraftApi.status === 'fulfilled') {
            dispatch(load({ list: listDraftApi.data.Data, id_status_tab: 1 }))
        }
    }, [listDraftApi.status]);
    useEffect(() => {
        if (listActiveExecutorApi.status === 'fulfilled') {
            dispatch(load({ list: listActiveExecutorApi.data.Data, id_status_tab: 2 }))
        }
    }, [listActiveExecutorApi.status]);
    useEffect(() => {
        if (listActiveObserverApi.status === 'fulfilled') {
            dispatch(load({ list: listActiveObserverApi.data.Data, id_status_tab: 3 }))
        }
    }, [listActiveObserverApi.status]);
    useEffect(() => {
        if (listFutureApi.status === 'fulfilled') {
            dispatch(load({ list: listFutureApi.data.Data, id_status_tab: 4 }))
        }
    }, [listFutureApi.status]);
    useEffect(() => {
        if (listCompleteApiResult.status === 'fulfilled') {
            dispatch(load({ list: listCompleteApiResult.data.Data, id_status_tab: 5 }))
            setFilterCompleteLoading(false);
        }
    }, [listCompleteApiResult.status]);

    /*****************************END API СПИСОК ЗАДАЧ***********************/
    /*********************************START ФИЛЬТР***************************/

    function filterHandlerProject(id, id_filter) {
        dispatch(filterProject({ id_project: id, id_status_tab: id_filter }));
    }
    function filterHandlerCompleteDateStart(e) {

        setFilterCompleteDateStart(e.target.value);

        const date_start = e.target.value;
        const date_end = filterCompleteDateEnd;

        if (date_start !== undefined & date_end !== undefined) {
            setFilterCompleteLoading(true);
            setFilterCompleteError(null);

            listCompleteApi({ Date_Start: date_start, Date_End: date_end });
        }

    }
    function filterHandlerCompleteDateEnd(e) {

        setFilterCompleteDateEnd(e.target.value);

        const date_start = filterCompleteDateStart;
        const date_end = e.target.value;

        if (date_start !== undefined & date_end !== undefined) {
            setFilterCompleteLoading(true);
            setFilterCompleteError(null);
            listCompleteApi({ Date_Start: date_start, Date_End: date_end });
        }

    }
    /**********************************END ФИЛЬТР****************************/


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="main-card mb-3 card">
                        <div className="card-header-tab card-header-tab-animation card-header">
                            <div className="card-header-title">
                                <UncontrolledButtonDropdown>
                                    <Button onClick={(e) => modalInfoHandler(e)} color="secondary"><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i>Добавить задачу</Button>
                                    <DropdownToggle className="dropdown-toggle-split" caret color="secondary" />
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => taskChangeHandler(1)}>Выполнят один из ответственных</DropdownItem>
                                        <DropdownItem onClick={() => taskChangeHandler(2)}>Выполняет каждый ответственный</DropdownItem>
                                        <DropdownItem onClick={() => taskChangeHandler(3)}>Ответственные указаны в чек листе</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>

                                <ModalAddInfo show={modalAddInfoShow} onHide={() => setModalAddInfoShow(false)} listCompleteApi={listCompleteApi} />
                                {getTempApiResult.status === 'fulfilled' && <ModalTask key={1} show={modalTaskShow} onHideTask={() => setModalTaskShow(false)} edit={false} listCompleteApi={listCompleteApi} model={getTempApiResult.data.Data} completeCheck={false} />}

                                {logTaskChange !== null && <div className="form_error ml-30">{logTaskChange}</div>}
                                {loadingTaskChange === true && <FontAwesomeIcon className="ml-10" icon={faSpinner} size="2x" />}
                            </div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a
                                        id="tab_1"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab1Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faArchive} className="mr-5" /> Черновики {listData.listAll.filter(a => a.ID_Status_Tab == 1).length > 0 && <span className="badge badge-pill badge-success">{listData.listAll.filter(a => a.ID_Status_Tab == 1).length}</span>}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_2"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab2Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faCheck} className="mr-5" /> Исполнитель  {listData.listAll.filter(a => a.ID_Status_Tab == 2).length > 0 && <span className="badge badge-pill badge-success">{listData.listAll.filter(a => a.ID_Status_Tab == 2).length}</span>}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_3"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab3Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faEye} className="mr-5" /> Наблюдатель  {listData.listAll.filter(a => a.ID_Status_Tab == 3).length > 0 && <span className="badge badge-pill badge-success">{listData.listAll.filter(a => a.ID_Status_Tab == 3).length}</span>}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_4"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab4Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faRocket} className="mr-5" /> Будущие {listData.listAll.filter(a => a.ID_Status_Tab == 4).length > 0 && <span className="badge badge-pill badge-success">{listData.listAll.filter(a => a.ID_Status_Tab == 4).length}</span>}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        id="tab_5"
                                        onClick={tabHandleClick}
                                        data-toggle="tab"
                                        className={tab5Show ? "nav-link active show" : "nav-link show"}
                                    >
                                        <FontAwesomeIcon icon={faFlagCheckered} className="mr-5" /> Выполненные
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className={tab1Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="mt-15 mb-15">
                                        <FilterProject key={1} id_filter={1} list={listData.listFilter.filter(a => a.id_status_tab === 1)} clickHandler={filterHandlerProject} show_count={true} />
                                    </div>
                                    <div className="">
                                        <List key={1} list={listData.list.filter(a => a.ID_Status_Tab === 1)} isLoading={listDraftApi.isLoading} listCompleteApi={listCompleteApi} />
                                    </div>
                                </div>
                                <div className={tab2Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div>
                                        <FilterProject key={2} id_filter={2} list={listData.listFilter.filter(a => a.id_status_tab === 2)} clickHandler={filterHandlerProject} show_count={true} />
                                    </div>
                                    <div className="mt-20">
                                        <List key={2} list={listData.list.filter(a => a.ID_Status_Tab === 2)} isLoading={listActiveExecutorApi.isLoading} listCompleteApi={listCompleteApi} />
                                    </div>
                                </div>
                                <div className={tab3Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div>
                                        <FilterProject key={3} id_filter={3} list={listData.listFilter.filter(a => a.id_status_tab === 3)} clickHandler={filterHandlerProject} show_count={true} />
                                    </div>
                                    <div className="mt-20">
                                        <List key={3} list={listData.list.filter(a => a.ID_Status_Tab === 3)} isLoading={listActiveObserverApi.isLoading} listCompleteApi={listCompleteApi} />
                                    </div>
                                </div>
                                <div className={tab4Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div>
                                        <FilterProject key={4} id_filter={4} list={listData.listFilter.filter(a => a.id_status_tab === 4)} clickHandler={filterHandlerProject} show_count={true} />
                                    </div>
                                    <div className="mt-20">
                                        <List key={4} list={listData.list.filter(a => a.ID_Status_Tab === 4)} isLoading={false} listCompleteApi={false} />
                                    </div>
                                </div>
                                <div className={tab5Show ? "tab-pane show active" : "tab-pane show"} role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label className="form-label">Дата завершения</label>
                                            <Form.Control type="date" defaultValue={filterCompleteDateStart} onChange={(e) => filterHandlerCompleteDateStart(e)} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label">Дата завершения</label>
                                            <Form.Control type="date" defaultValue={filterCompleteDateEnd} onChange={(e) => filterHandlerCompleteDateEnd(e)} />
                                        </div>
                                        <div className="col-md-8">

                                        </div>
                                        {filterCompleteError !== null && <div className="col-md-12 form_error">{filterCompleteError}</div>}
                                        {filterCompleteLoading === true && (
                                            <>
                                                <div className="col-md-4">
                                                    <div className="loader_01"></div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="mt-20">
                                        <List key={5} list={listData.list.filter(a => a.ID_Status_Tab == 5)} isLoading={listCompleteApi.isLoading} listCompleteApi={listCompleteApi} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
