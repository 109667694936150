import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { format } from "date-fns";

//Фильтр с датами
function FilterDate({ changHandler, defaultVal }) {

    useEffect(() => {
        clickItemHandler(defaultVal)
    }, [defaultVal])

    const [errorText, setErrorText] = useState(null);
    const [list, setList] = useState([
        { id: 1, name: 'Сегодня', checked: false }
        , { id: 2, name: 'Вчера', checked: false }
        , { id: 3, name: 'Неделя', checked: false }
        , { id: 4, name: 'Месяц', checked: false }
        , { id: 5, name: 'Год', checked: true }
    ]);
    const [dateStart, setDateStart] = useState('1970-01-01');
    const [dateEnd, setDateEnd] = useState('1970-01-01');

    //Клик на готовый период
    function clickItemHandler(id) {

        const new_list = list.map((model, i) => {
            if (model.id === id) {

                return { ...model, checked: true };
            }
            else {

                return { ...model, checked: false };
            }
        });
        setList(new_list);

        //Заполняем даты
        var date_start = null;
        var date_end = null;

        const today = new Date();

        if (id == 1) {
            date_start = format(today, 'yyyy-MM-dd');
            date_end = format(today, 'yyyy-MM-dd');
            setDateStart(date_start);
            setDateEnd(date_end);
        }
        if (id === 2) {

            today.setDate(today.getDate() - 1);

            date_start = format(today, 'yyyy-MM-dd');
            date_end = format(today, 'yyyy-MM-dd');

            setDateStart(date_start);
            setDateEnd(date_end);
        }
        if (id === 3) {

            const date_start_ = new Date();
            date_start_.setDate(today.getDate() - 6);

            date_start = format(date_start_, 'yyyy-MM-dd');
            date_end = format(today, 'yyyy-MM-dd');

            setDateStart(date_start);
            setDateEnd(date_end);
        }
        if (id === 4) {

            const date_start_ = new Date();
            date_start_.setDate(today.getDate() - 30);

            date_start = format(date_start_, 'yyyy-MM-dd');
            date_end = format(today, 'yyyy-MM-dd');

            setDateStart(date_start);
            setDateEnd(date_end);
        }
        if (id === 5) {

            const date_start_ = new Date();
            date_start_.setDate(today.getDate() - 365);

            date_start = format(date_start_, 'yyyy-MM-dd');
            date_end = format(today, 'yyyy-MM-dd');

            setDateStart(date_start);
            setDateEnd(date_end);
        }

        changHandler(date_start, date_end)
    }

    //Изменение дат вручную
    function changeDateHandler(e, type) {
        const new_list = list.map((model, i) => {
            return { ...model, checked: false };
        });
        setList(new_list);

        var date_start = null;
        var date_end = null;

        if (type === 'date_start') {
            setDateStart(e.target.value);
            date_start = e.target.value;
            date_end = dateEnd;
        }
        if (type === 'date_end') {
            setDateEnd(e.target.value)
            date_start = dateStart;
            date_end = e.target.value;
        }

        setErrorText(null);

        if (date_start === '') {
            setErrorText('Заполните дату начала');
        }
        else if (date_end === '') {
            setErrorText('Заполните дату окончания');
        }
        else if (date_start > date_end) {
            setErrorText('Дата окончания должна быть больше или равна даты начала');
        }
        else {
            changHandler(date_start, date_end)
        }
    }

    return (
        <>
            <div className="d-flex flex-row bd-highlight">
                <div className="p-2 bd-highlight">
                    {list.map((model) => (
                        model.checked ?
                            (<a key={model.id} onClick={(e) => clickItemHandler(model.id)} className="mr-2 btn-square btn-transition btn btn-outline-secondary active">{model.name}</a>)
                            :
                            (<a key={model.id} onClick={(e) => clickItemHandler(model.id)} className="mr-2 btn-square btn-transition btn btn-outline-secondary">{model.name}</a>)
                    ))}
                </div>
                <div className="p-2 bd-highlight">
                    <Form.Group>
                        <Form.Control type="date" value={dateStart} onChange={(e) => changeDateHandler(e, 'date_start')} />
                    </Form.Group>
                </div>
                <div className="p-2 bd-highlight">
                    <Form.Group>
                        <Form.Control type="date" value={dateEnd} onChange={(e) => changeDateHandler(e, 'date_end')} />
                    </Form.Group>
                </div>

            </div>
            {errorText !== null && (<div className="form_error p-2">{errorText}</div>)}

        </>

    )
}

export default FilterDate;