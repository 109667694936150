function FilterProject({ id_filter, list, clickHandler, show_all, show_count }) {

    /*
    ФОРМАТ list:
            id - ИД проекта
            name - Название проекта
            count - Кол-во элементов в проекте
            selected - выбран элемент

            id_filter- ИД фильтра. Для случаев, когда на странице несколько фильтров. Пример в задачах несколько вкладок с фильтрами
            show_all - Показывать все элементы, даже если 0
            show_count - Показывать счетчик количества элементов
    */

    //Событие изменения фильтра
    function clickHandler_(id) {
        clickHandler(id, id_filter);
    }

    return (
        <div>
            {
                list.filter(a => a.count > 0 || show_all === true).map((model) => (
                    <button
                        key={model.id + '_' + id_filter}
                        onClick={() => clickHandler_(model.id)}
                        className={model.selected === true ? ("mr-15 mt-15 btn-pill btn btn-dashed btn-outline-info active") : ("mr-15 mt-15 btn-pill btn btn-dashed btn-outline-info")}>
                        {model.name} {show_count && (<>{model.count != null && (<>({model.count})</>)}</>)}
                    </button>
                ))
            }
        </div>
    )
}

export default FilterProject;