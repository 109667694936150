import { createSlice } from '@reduxjs/toolkit'

const parameterProjectDisplayList = createSlice({
    name: 'parameterProjectDisplayList',
    initialState: {
        list: []
    },
    reducers: {
        load(state, action) {
            const id_owner = action.payload.id_owner; //1-Задача; 2-Регулярная задача; 3-Доска; 4-Регулярная доска

            //Обновляем коллекции
            state.list = [...state.list.filter(a => a.ID_Owner !== id_owner), ...action.payload.data];
        },
        add(state, action) {
            state.list = [...state.list, action.payload.model];
        },
        update(state, action) {
            state.list = state.list.filter(a => a.ID !== action.payload.model.ID);
            state.list = [...state.list, action.payload.model];
        },
        remove(state, action) {
            const id = action.payload.id;
            state.list = state.list.filter(a => a.ID !== id);
        }
    }
})

export const { load, add, update, remove } = parameterProjectDisplayList.actions
export default parameterProjectDisplayList.reducer