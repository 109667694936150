import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateForward, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useCompleteCancelMutation, useListActiveQuery, useLazyListCompletedQuery, useListDraftQuery, useListFutureQuery, useLazyUserStatusGetQuery, useLazyGetQuery } from "../../../api/tracker/task";
import { useEffect, useState } from "react";
import { update } from '../../../reducers/models/taskList'
import { useDispatch } from "react-redux";

function ModalTaskCompleteCancel(props) {
    const { show, onHide, onHideTask, id_task /*, listCompleteApi */ } = props;

    const dispatch = useDispatch()

    //Действия при открытии/закрытии модального окна
    useEffect(() => {
        //Действия при открытии модального окна
        if (show == true) {

        }
        //Действия при закрытии модального окна
        if (show == false) {
            setLog(null);
            setLoading(false);
        }
    }, [show]);

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);

    const [complete, { data: completeData, status: completeStatus }] = useCompleteCancelMutation();
    const [userStatusApi] = useLazyUserStatusGetQuery();
    const [getApi] = useLazyGetQuery();  

    function completeHandler() {
        setLoading(true);
        complete({ ID_Task: id_task })
            .unwrap()
            .then(res => {
                //Обновляем список задач(reducer)
                userStatusApi({ ID_Task: id_task })
                    .unwrap()
                    .then(status => {
                        getApi({ ID: id_task })
                            .unwrap()
                            .then(task => {
                                dispatch(update({ task: task.Data, id_status_tab: status.Data }));
                                onHide();
                                onHideTask();
                                setLoading(false);
                            })
                    });
            });
    }

    return (
        <>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            <i className="mr-5"><FontAwesomeIcon icon={faRotateForward} /></i> Отмена завершения задачи
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Подтвердите отмену завершения задачи
                    {log !== null && <div className="form_error text-center mt-15">{log}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                        onClick={onHide}
                    >
                        <FontAwesomeIcon icon={faXmark} className="mr-5" />
                        Закрыть
                    </button>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-light"
                        disabled={loading}
                        onClick={() => completeHandler()}
                    >
                        {loading ? (
                            "Загрузка..."
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faRotateForward} className="mr-5" /> Отменить
                            </>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ModalTaskCompleteCancel;
