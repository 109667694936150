import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import SelectSearch from "../../../../components/ui/SelectSearch";
import { useChecklistAddMutation, useCheckListListQuery, useChecklistUpdateMutation, useLazyGetQuery, useLazyUserStatusGetQuery } from "../../../../api/tracker/task";
import { useUserListQuery } from "../../../../api/tracker/project";
import { update as updateReducer } from '../../../../reducers/models/taskList'
import { useDispatch } from "react-redux";

function CheckListModalAdd({ show, onHide, id_task, id_project, model, edit }) {

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()

    //API
    const userListApi = useUserListQuery({ ID_Project: id_project });
    const [userList, setUserList] = useState([]);
    const checkListApi = useCheckListListQuery({ ID_Task: id_task });

    const [userStatusApi, userStatusApiResult] = useLazyUserStatusGetQuery();
    const [getApi, getApiResult] = useLazyGetQuery();

    useEffect(() => {
        //открытие
        if (show === true) {
            if (edit) {
                setInputIdUser({ "value": model.ID_User, "label": model.Name_User, "checked": true });
                setInputName(model.Name);
                setInputDescription(model.Description);
            }
        }
        //Действия при закрытии модального окна
        if (show == false) {
            setInputIdUser(null);
            setInputName(null);
            setInputDescription(null);
        }
    }, [show]);

    //ПОДГРУЗКА ПОЛЬЗОВАТЕЛЕЙ
    useEffect(() => {
        if (userListApi.status === 'fulfilled') {
            if (userListApi.data.Status == 1) {                
                const nextUserList = userListApi.data.Data.map(item => {
                    return {
                        value: item.ID_User, label: item.User_Name, checked: false
                    };
                })
                setUserList(nextUserList);
            }
            else {
                setLog(userListApi.data.Error);
            }
        }
        if (userListApi.status === 'rejected') {
            setLog('Неизвестная ошибка!');
        }
    }, [userListApi.status]);

    //ПОЛЯ ФОРМЫ
    const [inputIdUser, setInputIdUser] = useState(null);
    const [inputName, setInputName] = useState(null);
    const [inputDescription, setInputDescription] = useState(null);

    //ДОБАВЛЕНИЕ ЗАДАЧИ
    const [add, { data: addData, status: addStatus }] = useChecklistAddMutation();

    function addHandler() {
        setLoading(true);
        setLog(null);
        if (inputIdUser === null) {
            setLog('Выберите пользователя');
            setLoading(false);
        }
        else if (inputName === null) {
            setLog('Введите название задачи');
            setLoading(false);
        }
        else {
            add({ ID_Task: id_task, ID_User: inputIdUser.value, Name: inputName, Description: inputDescription })
                .unwrap()
                .then((res) => {
                    if (res.Status === 1) {

                        //Обновляем список задач(reducer)
                        userStatusApi({ ID_Task: id_task })
                            .unwrap()
                            .then(status => {
                                getApi({ ID: id_task })
                                    .unwrap()
                                    .then(task => {
                                        checkListApi.refetch();
                                        dispatch(updateReducer({ task: task.Data, id_status_tab: status.Data }));
                                        onHide();
                                        setLoading(false);
                                    })
                            }); 
                    }
                    else {
                        setLog(res.Error);
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    //console.log(err)
                    setLog('Неизвестная ошибка!');
                    setLoading(false);
                })
        }
    }

    //ИЗМЕНЕНИЕ ЗАДАЧИ
    const [update, { data: updateData, status: updateStatus }] = useChecklistUpdateMutation();

    function updateHandler() {
        setLoading(true);
        setLog(null);
        if (inputIdUser === null) {
            setLog('Выберите пользователя');
            setLoading(false);
        }
        else if (inputName === null) {
            setLog('Введите название задачи');
            setLoading(false);
        }
        else {
            update({ ID: model.ID, ID_User: inputIdUser.value, Name: inputName, Description: inputDescription })
                .unwrap()
                .then((res) => {
                    if (res.Status === 1) {
                        checkListApi.refetch();
                        onHide();
                        setLoading(false);
                    }
                    else {
                        setLog(res.Error);
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLog('Неизвестная ошибка!');
                    setLoading(false);
                })
        }
    }

    return (
        <>
            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <>
                            {edit ? (<><i className="mr-5"><FontAwesomeIcon icon={faEdit} /></i>Редактирование задачи в чек-лист</>) : (<><i className="mr-5"><FontAwesomeIcon icon={faPlus} /></i>Добавление задачи в чек-лист</>)}
                        </>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="grid-menu grid-menu-3col mb-30">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Пользователь</Form.Label>
                            <SelectSearch placeholder='Выберите пользователя' className="z-index-max" disabled={loading} options={userList} onChange={(e) => setInputIdUser(e)} defaultValue={inputIdUser} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Название</Form.Label>
                            <Form.Control type="text" disabled={loading} defaultValue={inputName} onChange={(e) => setInputName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Описание</Form.Label>
                            <Form.Control as="textarea" rows={3} onChange={(e) => setInputDescription(e.target.value)} defaultValue={inputDescription} />
                        </Form.Group>
                        {loading === true && (<span className="loader_01"></span>)}
                        {log != null && <div className="form_error text-center">{log}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {edit ?
                        (
                            <button
                                className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                                disabled={loading}
                                onClick={() => updateHandler()}
                            >
                                {loading ? (
                                    "Изменение..."
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faSave} className="mr-5" /> Сохранить
                                    </>
                                )}
                            </button>
                        ) :
                        (
                    <button
                                className="mr-5 btn-square btn-hover-shine btn btn-secondary"
                                disabled={loading}
                                onClick={() => addHandler()}
                            >
                                {loading ? (
                                    "Изменение..."
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faPlus} className="mr-5" /> Добавить
                                    </>
                                )}
                            </button>
                        )}


                </Modal.Footer>
            </Modal >
        </>
    );
}
export default CheckListModalAdd;
