import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useCheckListCompleteCancelMutation, useCheckListListQuery, useLazyGetQuery, useLazyUserStatusGetQuery } from "../../../../api/tracker/task";
import { update } from '../../../../reducers/models/taskList'
import { useDispatch } from "react-redux";

function ModalCompleteCancel({ show, onHide, onHideDetail, model }) {

    const [log, setLog] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()

    const [completeCancel, { }] = useCheckListCompleteCancelMutation();
    const checkListApi = useCheckListListQuery({ ID_Task: model.ID_Task });
    const [userStatusApi, userStatusApiResult] = useLazyUserStatusGetQuery();
    const [getApi, getApiResult] = useLazyGetQuery();

    function completeCancelHandler() {
        setLoading(true);
        setLog(null);

        completeCancel({ ID: model.ID })
            .unwrap()
            .then((res) => {
                if (res.Status === 1) {
                    checkListApi.refetch();

                    //Обновляем список задач(reducer)
                    userStatusApi({ ID_Task: model.ID_Task })
                        .unwrap()
                        .then(status => {
                            getApi({ ID: model.ID_Task })
                                .unwrap()
                                .then(task => {
                                    dispatch(update({ task: task.Data, id_status_tab: status.Data }));
                                    onHide();
                                    //onHideDetail();
                                    setLoading(false);
                                })
                        });                   
                }
                else {
                    setLog(res.Error);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLog('Неизвестная ошибка!');
                setLoading(false);
            })
    }

    return (
        <>
            <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <i className="mr-10"><FontAwesomeIcon icon={faRotateLeft} /></i>Отмена завершение задачи
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center mt-10 mb-10">
                        Подтвердите отмену завершения задачи <b>«{model.Name}»</b>
                    </div>
                    {log != null && <div className="form_error text-center">{log}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="mr-5 btn-square btn-hover-shine btn btn-light"
                        disabled={loading}
                        onClick={() => completeCancelHandler()}
                    >
                        {loading ? (
                            "Отмена..."
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faRotateLeft} className="mr-5" /> Отменить
                            </>
                        )}
                    </button>


                </Modal.Footer>
            </Modal >
        </>
    );
}
export default ModalCompleteCancel;
