import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//https://habr.com/ru/articles/730916/
export const parameterApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: '/api_main/tracker/parameter/' }),
    tagTypes: ['parameterList', 'parameterProjectList'],
    reducerPath: 'ParameterApi',
    endpoints: (builder) => ({
        add: builder.mutation({
            query: (args) => {
                return {
                    url: 'add',
                    method: 'POST',
                    body: args,
                };
            },
            //invalidatesTags: ['parameterList'],
        }),
        update: builder.mutation({
            query: (args) => {
                return {
                    url: 'update',
                    method: 'POST',
                    body: args,
                };
            },
            // invalidatesTags: ['parameterList'],
        }),
        getTemp: builder.mutation({
            query: (args) => {
                return {
                    url: 'getTemp',
                    method: 'GET',
                    params: args
                };
            },
            //providesTags: ['projectList'],
        }),
        get: builder.mutation({
            query: (args) => {
                return {
                    url: 'get',
                    method: 'GET',
                    params: args
                };
            },
            //providesTags: ['projectList'],
        }),
        remove: builder.mutation({
            query: (args) => {
                return {
                    url: 'remove',
                    method: 'POST',
                    body: args,
                };
            }
        }),


        //Список параметров
        list: builder.mutation({
            query: (args) => {
                return {
                    url: 'list',
                    method: 'GET',
                    params: args
                };
            },
            // providesTags: ['parameterList'],
        }),
        //Список параметров в области отображения
        listByDisplay: builder.mutation({
            query: (args) => {
                return {
                    url: 'listByDisplay',
                    method: 'GET',
                    params: args
                };
            }
        }),
        displayList: builder.query({
            query: (args) => {
                return {
                    url: 'displayList',
                    method: 'GET',
                    params: args
                };
            },
        }),
        //Список областей отображения параметра
        displayListByParameter: builder.mutation({
            query: (args) => {
                return {
                    url: 'displayListByParameter',
                    method: 'GET',
                    params: args
                };
            }
        }),
        //Список областей отображения в проекте
        displayListByProject: builder.mutation({
            query: (args) => {
                return {
                    url: 'displayListByProject',
                    method: 'GET',
                    params: args
                };
            },
        }),

        //Список областей отображения типа параметра(Задача, Регулярная задача, Доска)
        projectDisplayListByOwnerParameter: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectDisplayListByOwnerParameter',
                    method: 'GET',
                    params: args
                };
            },
        }),

        projectList: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectList',
                    method: 'GET',
                    params: args
                };
            },
        }),


        //Список проектов типа параметра(Задача, Регулярная задача, Доска)
        projectListByOwnerParameter: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectListByOwnerParameter',
                    method: 'GET',
                    params: args
                };
            },
        }),
        //Список проектов параметра
        projectListByParameter: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectListByParameter',
                    method: 'GET',
                    params: args
                };
            }
            , providesTags: ['parameterProjectList'],
        }),
        projectAdd: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectAdd',
                    method: 'POST',
                    body: args,
                };
            }
            , invalidatesTags: ['parameterProjectList']
        }),
        projectRemove: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectRemove',
                    method: 'POST',
                    body: args,
                };
            }
        }),
        projectGet: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectGet',
                    method: 'GET',
                    params: args
                };
            },
        }),

        //Установит область отображения для проекта в компании
        projectDisplaySet: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectDisplaySet',
                    method: 'POST',
                    body: args,
                };
            },
        }),
        //Установит область отображения для проекта в компании массово
        projectDisplaySetMass: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectDisplaySetMass',
                    method: 'POST',
                    body: args,
                };

            },
            invalidatesTags: ['parameterProjectList']
        }),
        //Удалит область отображения из проекта в компании
        projectDisplayRemove: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectDisplayRemove',
                    method: 'POST',
                    body: args,
                };

            }
        }),
        //Обновит параметр
        projectDisplayUpdateMass: builder.mutation({
            query: (args) => {
                return {
                    url: 'projectDisplayUpdateMass',
                    method: 'POST',
                    body: args,
                };

            }
        }),


        referenceSetMass: builder.mutation({
            query: (args) => {
                return {
                    url: 'referenceSetMass',
                    method: 'POST',
                    body: args,
                };
            },
            //providesTags: ['projectList'],
        }),
        referenceRemove: builder.mutation({
            query: (args) => {
                return {
                    url: 'referenceRemove',
                    method: 'POST',
                    body: args,
                };
            },
            //providesTags: ['projectList'],
        }),
        referencelist: builder.mutation({
            query: (args) => {
                return {
                    url: 'referencelist',
                    method: 'GET',
                    params: args
                };
            },
            //providesTags: ['projectList'],
        }),
    }),
});

export const { useAddMutation, useUpdateMutation, useRemoveMutation, useGetTempMutation, useListMutation, useGetMutation, useProjectGetMutation
    , useProjectAddMutation, useProjectRemoveMutation, useProjectListByParameterMutation, useProjectListMutation, useProjectDisplayUpdateMassMutation
    , useProjectDisplaySetMutation, useProjectDisplaySetMassMutation, useProjectDisplayRemoveMutation
    , useReferencelistMutation, useReferenceSetMassMutation, useReferenceRemoveMutation
    , useDisplayListQuery
    , useDisplayListByParameterMutation, useDisplayListByProjectMutation, useListByDisplayMutation
    , useProjectDisplayListByOwnerParameterMutation, useProjectListByOwnerParameterMutation
} = parameterApi;