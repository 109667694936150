import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useProjectDisplayRemoveMutation } from "../../../api/tracker/parameter";
import { remove as removeReducer } from '../../../reducers/models/parameterDesignerTaskList'
import { useDispatch } from "react-redux";


function DesignerParamModalRemove(props) {
    const { show, onHide, selectedParam, listParam } = props;

    const dispatch = useDispatch();

    const [param, setParam] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    //ОТКРЫТИЕ/ЗАКРЫТИЕ МОДАЛЬНОЕ ОКНО
    useEffect(() => {
        //открытие
        if (show === true) {
            setError(null);
            setLoading(false)
        }
    }, [show]);


    useEffect(() => {
        if (selectedParam === null) {
            setParam([]);
        }
        else {
            setParam(selectedParam);
        }
    }, [selectedParam]);

    const [remove] = useProjectDisplayRemoveMutation();

    function removeHandler(id_display, id_parameter, id_project) {
        setLoading(true);
        remove({ ID_Display: id_display, ID_Parameter: id_parameter, ID_Project: id_project })
            .unwrap()
            .then(res => {
                if (res.Status === 1) {
                    dispatch(removeReducer({ id_parameter: id_parameter, id_project: id_project, id_display: id_display }));
                    onHide();
                }
                else {
                    setError(res.Error);
                }

                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                setError('Неизвестная ошибка');
            })
    }

    return (
        <>
            {param.length > 0 && (
                <Modal show={show} onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                            <div><i className="mr-5"><FontAwesomeIcon icon={faTrash} /></i> Удаление параметра</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        Подтвердите удаление параметра <b>«{param[0].Name_Parameter}»</b> <br></br>  из области <b>«{param[0].Name_Display}»</b>
                </Modal.Body>
                <Modal.Footer>
                    {error != null && <div className="form_error text-left">{error}</div>}
                    <button
                        className="btn-square btn-hover-shine btn btn-secondary"
                        type="submit"
                            onClick={() => removeHandler(param[0].ID_Display, param[0].ID_Parameter, param[0].ID_Project)}
                        disabled={loading}
                    >
                        {loading ? (
                            "Загрузка..."
                        ) : (
                            <div><FontAwesomeIcon icon={faTrash} className="mr-5" /> Удалить</div>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
            )}
        </>
    );
}
export default DesignerParamModalRemove;
