import { faCalendar, faHourglassEnd, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ModalCard from './ModalCard';
import { useTrackerGetMutation } from '../../../../api/tracker/taskR';
import StringToDateTime from '../../../../util/StringToDateTime';
import StringToDate from '../../../../util/StringToDate';
import { differenceInDays } from 'date-fns';


function ListActiveExecutor({ list }) {

    function DateStartEnd({ date_start, date_end }) {
        if (date_start !== null & date_end !== null) {

            const diff_days = differenceInDays(
                new Date(new Date()),
                new Date(date_end)
            )


            return (<>
                <FontAwesomeIcon icon={faCalendar} className='mr-10' />{StringToDate(date_start)} - {StringToDate(date_end)}
                {diff_days > 0 && <div className='text-danger'><b><FontAwesomeIcon icon={faHourglassEnd} className='mr-10' /> Просрочено {diff_days} дн.</b></div>}
            </>)
        }
        else if (date_start !== null & date_end === null) {
            return (<><FontAwesomeIcon icon={faCalendar} className='mr-10' />С {StringToDate(date_start)}</>)
        }
        else {
            return null;
        }
    }   

    const [modalComplete, setModalComplete] = useState(false);
    const [listData, setListData] = useState([]);

    useEffect(() => {
        if (list.length > 0) {
            //Устанавливаем для списка служебные поля
            setListData(list.map(artwork => {
                return { ...artwork, Sys_Loading: false };
            }));
        }
    }, [list]);

    /***************************START МОДЕЛЬ ЗАДАЧИ****************************/
    const [getModel, setGetModel] = useState(false);
    const [getApi, { data: getApiData, status: getApiStatus }] = useTrackerGetMutation();
    //Обработка получения временной задачи
    useEffect(() => {
        if (getApiStatus === 'fulfilled') {
            if (getApiData.Status == 1) {
                setGetModel(getApiData.Data);
                setModalComplete(true);
            }
            else {
                //setLog(getApiData.Error);
            }
            Loading(getApiData.Data.ID, false)
        }
        if (getApiStatus === 'rejected') {
            Loading(getApiData.Data.ID, false)
        }
    }, [getApiStatus]);
    /****************************END МОДЕЛЬ ЗАДАЧИ*****************************/

    function modalCompleteHandler(ID) {
        Loading(ID, true)
        getApi({ ID_TaskR_Tracker: ID })
    }

    function Loading(ID, Value) {
        setListData(list.map(row => {
            if (row.ID === ID) {
                return { ...row, Sys_Loading: Value };
            } else {
                return row;
            }
        }));
    }

    return (
        <>
            <ModalCard show={modalComplete} onHide={() => setModalComplete(false)} model={getModel} />

            {list.isLoading === true ? (
                <div>
                    <div className="loader_01"></div>
                </div>
            ) : (
                <div>
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                {listData.map((model) => (
                                    <tr className='row_select' key={model.ID}>
                                        <td>
                                            <div className='task_list_name'>{model.Name_Task}</div>
                                            <small>{model.Name_Project}</small><br></br>
                                            <small>{StringToDateTime(model.Date_Add)}</small>
                                        </td>
                                        <td><DateStartEnd date_start={model.Date} date_end={model.Date_End} /></td>

                                        <td>
                                            {model.Timetable_String} <br></br>
                                            <small>{model.Name_Type_Task}</small>
                                        </td>

                                        <td className='text-end'>
                                            <button key={model.ID}
                                                className="btn-square btn-hover-shine btn btn-secondary"
                                                onClick={() => modalCompleteHandler(model.ID)}
                                                disabled={model.Sys_Loading}
                                            >
                                                {model.Sys_Loading ? '…' : (<i><FontAwesomeIcon icon={faMagnifyingGlass} /></i>)}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div >
            )
            }
        </>
    );
}

export default ListActiveExecutor;